export class CaptureAdditionalDamage {
  hasAdditionalDamage?: boolean = undefined;
  additionalDamageTypes = [];

  damageAreas = [
    {
      id: 1,
      value: 'Front',
      location: 'Front',
      description: 'Front',
      label: 'Front',
      icon: 'icon-vehicle-front',
      dataUri: '',
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2',
      suggestionImage: '../../../assets/images/suggestions/Front.jpg'
    },
    {
      id: 2,
      description: 'Rear',
      value: 'Rear',
      location: 'Rear',
      label: 'Rear',
      icon: 'icon-vehicle-back',
      dataUri: '',
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2',
      suggestionImage: '../../../assets/images/suggestions/Back.jpg'
    },
    {
      id: 3,
      description: "Driver's side",
      value: 'DriversSide',
      location: 'DriversSide',
      label: "Driver's side",
      icon: 'icon-vehicle-right',
      dataUri: '',
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2',
      suggestionImage: '../../../assets/images/suggestions/Right-side.jpg'
    },
    {
      id: 4,
      description: 'Passengers side',
      value: 'PassengerSide',
      location: 'PassengerSide',
      label: 'Passengers side',
      icon: 'icon-vehicle-left',
      dataUri: '',
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2',
      suggestionImage: '../../../assets/images/suggestions/Left-side.jpg'
    },
    {
      id: 5,
      description: 'Roof',
      value: 'Roof',
      location: 'Roof',
      label: 'Roof',
      icon: 'icon-vehicle-top',
      dataUri: '',
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2',
      suggestionImage: '../../../assets/images/suggestions/Roof.jpg'
    },
    {
      id: 6,
      description: 'Under car',
      value: 'UnderCar',
      location: 'UnderCar',
      label: 'Under car',
      icon: 'icon-vehicle-chassis',
      dataUri: '',
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2',
      suggestionImage: '../../../assets/images/suggestions/Under-car.jpg'
    },
    {
      id: 7,
      description: 'Interior',
      value: 'Interior',
      location: 'Interior',
      label: 'Interior',
      icon: 'icon-vehicle-interior',
      dataUri: '',
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2',
      suggestionImage: '../../../assets/images/suggestions/Interior.jpg'
    },
    {
      id: 8,
      description: 'Mags or rims',
      value: 'MagsOrRims',
      location: 'MagsOrRims',
      label: 'Mags or rims',
      icon: 'icon-vehicle-wheel',
      dataUri: '',
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2',
      suggestionImage: '../../../assets/images/suggestions/Mags.jpg'
    }
  ];

  hasAdditionalDamageOptions = [
    {
      id: 1,
      description: 'Yes',
      value: true,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2'
    },
    { id: 2, description: 'No', value: false, unCheckedIcon: 'icon-outline', checkedIcon: 'icon-check-2' }
  ];
}
