// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-dialog {
  text-align: center;
}
.info-dialog .info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-dialog .info-icon .individual-icon {
  font-size: 3em;
}
.info-dialog .info-heading {
  font-size: var(--text-lg);
  color: var(--sub-6);
}
.info-dialog .info-text {
  font-size: var(--text-sm);
  color: var(--sub-5);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
