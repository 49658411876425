import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Utilities } from 'src/modules/@tih/helpers/utilities';

@Component({
  selector: 'tih-toggle-item',
  templateUrl: './toggle-item.component.html',
  styleUrls: ['./toggle-item.component.scss']
})
export class ToggleItemComponent {
  @Output() itemClick$ = new EventEmitter();

  isOpen = false;
  @Input() label = 'Toggle Item';
  @Input() hasContent = false;

  @Input() iconPlaceholder = '';
  @Input() iconSuccess = '';
  @Input() iconAdd = '';
  @Input() iconToggle = '';
  shouldToggle = true;

  @Input()
  set setShouldToggle(value) {
    this.shouldToggle = value;
    if (!value && this.content) {
      this.content.nativeElement.style.height = '0';
    }
  }

  @Input()
  set setIsOpen(value: boolean) {
    setTimeout(() => {
      this.toggleContentView(value);
    }, 0);
  }

  @ViewChild('content', {})
  private content: ElementRef<HTMLDivElement>;

  get titleCaseID(): string {
    return `item${Utilities.titleCase(this.label)}`;
  }

  onResize(): void {
    const section = this.content.nativeElement.firstElementChild;
    if (section && this.hasContent && this.shouldToggle) {
      this.content.nativeElement.style.height = (this.isOpen && `${section.scrollHeight}px`) || '0';
    }
  }

  itemClick(): void {
    if (this.hasContent && this.shouldToggle) {
      this.toggleContentView();
    } else {
      this.itemClick$.emit();
    }
  }

  imageClick(): void {
    this.itemClick$.emit();
  }

  private toggleContentView(toggleOpen?: boolean): void {
    this.isOpen = toggleOpen ?? !this.isOpen;
    const section = this.content?.nativeElement?.firstElementChild;
    if (section && this.hasContent && this.shouldToggle) {
      this.content.nativeElement.style.height = (this.isOpen && `${section.scrollHeight}px`) || '0';
    }
  }
}
