import { DatabaseContext } from '../../modules/@tih/budget/database-context/database.context';
import {
  Driver,
  Vehicle,
  VehicleInspection,
  SurveyQuestion,
  SurveyQuestionResponse,
  CallResponse
} from '../../modules/@tih/budget/database-context/tables/vehicle-inspection.model';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClientService } from '../../modules/@tih/httpclient/http-client.service';
import { map, take, tap } from 'rxjs/operators';
import { IVehicleInspectionResponse } from '../../modules/@tih/budget/interfaces/vehicle-inspection.interface';
import { UserService } from './user.service';
import { Utilities } from '../../modules/@tih/helpers/utilities';
import { Events } from '../../modules/@tih/gtm-datalayer/enums/events.enum';
import { Names } from '../../modules/@tih/gtm-datalayer/enums/names.enum';
import { StepNames } from '../../modules/@tih/gtm-datalayer/enums/step-names.enum';
import { GtmDataLayerService } from '../../modules/@tih/gtm-datalayer/gtm-data-layer.service';
import { CaptureSurveyResponseComponent } from '../../modules/@tih/budget/components/capture-survey-response/capture-survey-response.component';
import { DialogService } from '../../modules/@tih/dialog/services/dialog.service';

@Injectable({ providedIn: 'root' })
export class VehicleInspectionService {
  public activeInspection: VehicleInspection;
  public inspectionList: Array<VehicleInspection>;
  public noVehicles = false;

  private surveyQuestions: Array<SurveyQuestion>;
  private activeInspection$ = new BehaviorSubject<VehicleInspection>(null);
  private inspectionList$ = new BehaviorSubject<Array<VehicleInspection>>(null);

  constructor(
    private dbContext: DatabaseContext,
    private httpClient: HttpClientService,
    private userService: UserService,
    private dataLayerService: GtmDataLayerService,
    public dialogService: DialogService
  ) {
    this.activeInspection$.subscribe((inspection) => {
      this.activeInspection = inspection;
      if (inspection) {
        // Save to DB for PWA purposes, using put to override the ID
        this.dbContext.activeInspection.put({ id: 1, inspectionKey: inspection.getKey });
      }
    });
    this.inspectionList$.subscribe((inspections) => {
      if (inspections) {
        if (inspections.length == 0) {
          this.noVehicles = true;
        } else {
          this.noVehicles = false;
        }
        this.inspectionList = inspections;
      }
    });
  }

  private getInspectionFromDatabase(key: string) {
    return this.dbContext.vehicleInspections.get(key);
  }

  private removeInspectionFromDatabase(key: string) {
    return this.dbContext.vehicleInspections.delete(key);
  }

  public getAllInspectionsFromDatabase() {
    const activeUserId = localStorage.getItem('activeUserId');
    const dbInspections = this.dbContext.vehicleInspections.where('key');
    if (activeUserId) {
      return dbInspections.startsWith(activeUserId)?.toArray();
    } else {
      return dbInspections.equals('')?.toArray();
    }
  }

  private getAllInspectionsFromApi() {
    return this.httpClient
      .getSelf<Array<IVehicleInspectionResponse>>('vehicle/GetAllVehiclesForUser')
      .pipe(map((vehicles) => vehicles.map((vehicle) => this.mapToVehicleInspection(vehicle))));
  }

  // ToDo - don't need this for now, but might be worth it in the future
  //   private getInspectionFromApi(policy: string, vehicleNumber: string) {
  //     return this.httpClient
  //       .getSelf<IVehicleInspectionResponse>(`vehicle/GetVehicleById?policy=${policy}&vehicleNumber=${vehicleNumber}`)
  //       .pipe(map((vehicle) => this.mapToVehicleInspection(vehicle)));
  //   }

  private bulkAddNewInspections(vehicleInspections: VehicleInspection[]) {
    this.getAllInspectionsFromDatabase().then((dbInspections) => {
      let newList = dbInspections;

      const filteredList = vehicleInspections.filter((itemInList) => {
        return !dbInspections.some((itemInDb) => {
          if (itemInDb.key === itemInList.key) {
            if (itemInDb.status === 'Complete') {
              this.removeInspectionFromDatabase(itemInDb.key);
              newList = newList.filter((newListInspection) => newListInspection.key !== itemInDb.key);
              return false;
            }
            return true;
          }
          return false;
        });
      });

      if (filteredList) {
        this.dbContext.vehicleInspections.bulkAdd(filteredList);
        newList = newList.concat(filteredList);
      }
      this.inspectionList$.next(newList);
    });
  }

  public saveActiveInspection() {
    this.dbContext.vehicleInspections.put(this.activeInspection$.getValue());
    this.updateActiveInspectionInList(false);
  }

  public saveInspection(inspection) {
    if (inspection) {
      this.dbContext.vehicleInspections.put(inspection);
    }
  }

  public setActiveInspection(key: string) {
    this.getInspectionFromDatabase(key).then((inspection) => {
      if (!inspection) {
        // Figured if we are going to the backend anyways, we might as well get everything
        this.getAllInspectionsFromApi().subscribe((vehicles) => {
          const activeVehicle = vehicles.find((vehicle) => vehicle.key === key);
          this.activeInspection$.next(activeVehicle);
          this.bulkAddNewInspections(vehicles);
        });
      } else {
        if (!this.inspectionList) {
          this.setInspectionList(false);
        }
        this.activeInspection$.next(inspection);
      }
    });
  }

  public async setInspectionList(callApi = true) {
    this.getAllInspectionsFromDatabase().then((dbInspections) => {
      if (dbInspections && dbInspections.length > 0) {
        this.inspectionList$.next(dbInspections);
      }

      if (callApi) {
        this.getAllInspectionsFromApi()
          .pipe(take(1))
          .subscribe((apiInspections) => {
            this.bulkAddNewInspections(apiInspections);
          });
      }
    });
  }

  pushToDataLayer(inspection, error: number, type: 'success' | 'unsuccessful') {
    this.dataLayerService.dataLayerInit(
      Events.FormSuccessSubmit,
      inspection.policyNumber,
      Names.Inspections,
      StepNames.SubmittedSelfInspection
    );
    this.dataLayerService.addMessageToDataLayer({ errors: error, type: type });
    this.dataLayerService.pushToDataLayer();
  }

  public submitInspection(index?: number) {
    const inspection = this.inspectionList[index];
    inspection.isUploading = true;
    this.inspectionList[index].uploadProgress = 0;
    this.inspectionList[index].uploadMessage = null;

    return this.httpClient
      .postSelf<any>('inspection/SubmitInspection', {
        vehicleInspection: this.inspectionList[index],
        userDetails: this.userService.activeUser
      })
      .pipe(
        tap((response) => {
          if (response.status === 'progress') {
            inspection.isUploading = true;
            this.inspectionList[index].uploadProgress = response.progress;
            this.inspectionList[index].uploadMessage = `${response.progress}% (${Utilities.bytesToMegaBytes(
              response.loaded
            )} MB of ${this.inspectionList[index]?.uploadSize.megaBytes} MB)`;
          }
        })
      )
      .subscribe(
        (response) => {
          if (response.status !== 'progress' && response.status !== 'unhandled') {
            const shouldOpenDialog = this.isLastInspectionInProgress(inspection);
            inspection.isUploading = false;
            if (response.success) {
              inspection.inspectionId = response.inspectionId;
              inspection.status = 'Complete';
              inspection.uploadSuccess = true;
              inspection.uploadMessage = 'Upload successful';
              this.pushToDataLayer(inspection, 0, 'success');
            } else {
              inspection.inspectionId = response.inspectionId;
              inspection.uploadSuccess = false;
              inspection.uploadMessage = 'Upload failed';
              inspection.uploadProgress = 0;
              this.pushToDataLayer(inspection, 1, 'unsuccessful');
            }

            this.saveInspection(inspection);

            if (shouldOpenDialog) {
              //Timing-out here to prevent survey from showing before user see/can read the upload result status on the homepage.
              setTimeout(() => {
                this.openSurveyDialog(inspection.policyNumber);
              }, 500);
            }
          }
        },
        (error: unknown) => {
          console.error(error);
          inspection.uploadMessage = 'Upload failed';
          inspection.isUploading = false;
          inspection.uploadProgress = 0;
          this.saveInspection(inspection);
          this.pushToDataLayer(inspection, 1, 'unsuccessful');
        }
      );
  }

  openSurveyDialog(policyNumber: string) {
    const dialogConfig = {
      data: {
        title: 'Inspection Survey',
        dialogStyles: {
          height: '100%'
        }
      },
      displayType: CaptureSurveyResponseComponent
    };

    this.getInspectionSurveyQuestions(policyNumber).subscribe((result) => {
      if (result && result.payload) {
        this.surveyQuestions = result.payload;
        this.dialogService.open(dialogConfig).then((service) => {
          service.view.pipe(take(1)).subscribe((component) => {
            component.instance.setSurveyQuestions(this.surveyQuestions);
            component.instance.confirmClick$.subscribe((survey) => {
              this.saveInspectionSurveyQuestions(survey, policyNumber).subscribe();
            });
          });
        });
      }
    });
  }

  getInspectionSurveyQuestions(policyNum: string) {
    return this.httpClient.getSelf<CallResponse<Array<SurveyQuestion>>>(
      `survey/GetInspectionQuestion?policyNumber=${policyNum}`
    );
  }

  isLastInspectionInProgress(currentInspection: VehicleInspection) {
    const totalInspectionsInProgress = this.inspectionList.filter(
      (item) => (item.uploadProgress || item.uploadProgress === 0) && item.isUploading
    );
    if (
      totalInspectionsInProgress.length === 0 ||
      (totalInspectionsInProgress.length === 1 &&
        totalInspectionsInProgress[0].inspectionId === currentInspection.inspectionId)
    ) {
      return true;
    }
    return false;
  }

  saveInspectionSurveyQuestions(surveyAnswers: Array<SurveyQuestionResponse>, policyNum: string) {
    return this.httpClient.postSelf(`survey/SaveInspectionAnswers`, {
      AnswerList: surveyAnswers,
      PolicyNumber: policyNum
    });
  }

  public updateActiveInspectionInList(autoSubmit: boolean) {
    const index = this.inspectionList.findIndex(
      (inspection) => inspection.vehicleNumber === this.activeInspection.vehicleNumber
    );
    this.inspectionList[index] = this.activeInspection;
    if (autoSubmit) {
      this.submitInspection(index);
    }
  }

  private mapToVehicleInspection(vehicle: IVehicleInspectionResponse): VehicleInspection {
    const vehicleInspectionItem = new VehicleInspection();
    vehicleInspectionItem.idNumber = localStorage.getItem('activeUserId');
    vehicleInspectionItem.vehicleNumber = vehicle.vehicleSequenceNumber;
    vehicleInspectionItem.type = vehicle.vehicleType;
    vehicleInspectionItem.vehicle = this.setVehicle(vehicle);
    vehicleInspectionItem.driver = this.setDriver(
      vehicle.regularDriverFirstName,
      vehicle.regularDriverLastName,
      vehicle.regularDriverIdNumber,
      vehicle.regularDriverDateOfBirth
    );
    vehicleInspectionItem.status = 'Not started';
    vehicleInspectionItem.brand = 'Budget';
    vehicleInspectionItem.policyNumber = vehicle.policyNumber;
    vehicleInspectionItem.lastModified = new Date();
    vehicleInspectionItem.getKey;
    return vehicleInspectionItem;
  }

  private setVehicle(vehicleResponse: IVehicleInspectionResponse) {
    const vehicle = new Vehicle();
    vehicle.description = vehicleResponse.vehicleDescription.replace(vehicleResponse.vehicleYear.toString(), '');
    vehicle.year = vehicleResponse.vehicleYear;
    vehicle.color = vehicleResponse.vehicleColour;
    vehicle.colorCode = vehicleResponse.vehicleColourCode;
    vehicle.mmCode = vehicleResponse.vehicleMMCode;
    vehicle.registrationNumber = vehicleResponse.vehicleRegNumber;
    vehicle.vinNumber = vehicleResponse.vehicleVinNumber;
    return vehicle;
  }

  private setDriver(name: string, surname: string, idNumber: string, dob: string) {
    const driver = new Driver();
    driver.name = name;
    driver.surname = surname;
    driver.idNumber = idNumber;
    driver.dateOfBirth = dob;
    return driver;
  }
}
