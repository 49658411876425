// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  margin-bottom: var(--header-height);
  z-index: 2;
  position: relative;
}
.header .header__navbar {
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  height: var(--header-height);
  right: 0;
  align-items: center;
  background-color: var(--sub-2);
}
.header .header__navbar .navbar__menu-toggle {
  flex: 0.1;
  max-width: 45px;
  position: relative;
  z-index: 1;
}
.header .header__navbar .navbar__menu-toggle.navbar__menu-toggle--open .box__inner {
  transform: translate3d(0, 6px, 0) rotate(45deg);
  background-color: var(--sub-1) !important;
}
.header .header__navbar .navbar__menu-toggle.navbar__menu-toggle--open .box__inner:before {
  transform: rotate(-45deg) translate3d(-5.71429px, -4px, 0);
  opacity: 0;
}
.header .header__navbar .navbar__menu-toggle.navbar__menu-toggle--open .box__inner:after {
  transform: translate3d(0, -13px, 0) rotate(-90deg);
}
.header .header__navbar .navbar__menu-toggle .menu-toggle__hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}
.header .header__navbar .navbar__menu-toggle .menu-toggle__hamburger .hamburger__box {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 15px;
}
.header .header__navbar .navbar__menu-toggle .menu-toggle__hamburger .hamburger__box .box__inner {
  position: absolute;
  width: 100%;
  height: 2.5px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: all;
  border-radius: 4px;
  background-color: var(--main-1);
}
.header .header__navbar .navbar__menu-toggle .menu-toggle__hamburger .hamburger__box .box__inner:before, .header .header__navbar .navbar__menu-toggle .menu-toggle__hamburger .hamburger__box .box__inner:after {
  position: absolute;
  width: 100%;
  height: inherit;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: inherit;
  content: "";
}
.header .header__navbar .navbar__menu-toggle .menu-toggle__hamburger .hamburger__box .box__inner:before {
  top: 6px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity;
}
.header .header__navbar .navbar__menu-toggle .menu-toggle__hamburger .hamburger__box .box__inner:after {
  top: 13px;
}
.header .header__navbar .navbar__logo {
  padding: 10px 5px;
}
.header .header__navbar .navbar__logo img {
  width: 100px;
}
.header .header__navbar .navbar__profile {
  display: inline-flex;
  flex: auto;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 5px;
}
.header .header__navbar .navbar__profile .icon {
  display: inherit;
  padding: 5px;
  color: var(--sub-5);
}
.header .header__navbar .navbar__profile .profile__photo {
  padding: 0 5px;
}
.header .header__navbar .navbar__profile .profile__photo .photo__image {
  position: relative;
  background-color: var(--main-1);
  border-radius: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(0.9);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .header__navbar .navbar__profile .profile__photo .photo__image .photo__image--initials {
  color: var(--sub-1);
}
.header .header__navbar .navbar__menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -1;
}
.header .header__navbar .navbar__menu:not(.navbar__menu---open) {
  top: unset;
  bottom: unset;
  left: unset;
  right: unset;
}
.header .header__navbar .navbar__menu.navbar__menu---open {
  z-index: 0;
}
.header .header__navbar .navbar__menu.navbar__menu---open .menu__overlay {
  right: 0;
}
.header .header__navbar .navbar__menu.navbar__menu---open .menu__sidebar {
  left: 0;
}
.header .header__navbar .navbar__menu .menu__overlay {
  content: "";
  height: 100%;
  width: 100%;
  right: -100%;
  position: fixed;
  background-color: #106cad;
  opacity: 0.3;
  transition: all 0.4s linear;
  top: 0;
  z-index: 1;
}
.header .header__navbar .navbar__menu .menu__sidebar {
  position: fixed;
  background-color: #106cad;
  width: 250px;
  height: 100%;
  left: -100%;
  top: 0;
  transition: all 0.3s linear;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--sub-1);
}
.header .header__navbar .navbar__menu .menu__sidebar .sidebar__header {
  display: flex;
  align-items: center;
  height: 52px;
  position: relative;
  top: -3px;
  padding-left: 45px;
  padding-right: 15px;
}
.header .header__navbar .navbar__menu .menu__sidebar ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}
.header .header__navbar .navbar__menu .menu__sidebar ul li {
  width: 100%;
}
.header .header__navbar .navbar__menu .menu__sidebar ul li a {
  padding: 15px;
  width: 100%;
  display: inline-block;
  position: relative;
  margin: 0;
  height: auto;
  padding-left: 75px;
  color: var(--sub-1);
}
.header .header__navbar .navbar__menu .menu__sidebar ul li a.icon:before {
  position: absolute;
  left: 40px;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.header .header__navbar .navbar__menu .menu__sidebar .sidebar__menu-items {
  border-bottom: 1px solid #ecedef;
}
.header .header__navbar .navbar__menu .menu__sidebar .sidebar__menu-items a {
  text-decoration: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
