// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video {
  position: absolute;
  height: 100%;
  object-fit: cover;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #a0a0a0;
}

.rotate-dialog {
  display: none;
}
.rotate-dialog .rotate-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rotate-dialog .rotate-heading {
  font-size: var(--text-lg);
  color: var(--sub-6);
}
.rotate-dialog .rotate-text {
  font-size: var(--text-sm);
  color: var(--sub-5);
}
@media (orientation: portrait) {
  .rotate-dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
    justify-content: center;
    align-items: center;
    color: black;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
