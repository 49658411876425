import { Component } from '@angular/core';
import { CameraOverlayComponent } from 'src/modules/@tih/budget/components/camera-overlay.component/camera-overlay.component';
import { VehicleInspectionService } from '../services/vehicle-inspection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CameraService } from 'src/modules/@tih/camera/services/camera.service';
import { CaptureWindscreenDamageComponent } from 'src/modules/@tih/budget/components/capture-windscreen-damage/capture-windscreen-damage.component';
import { DialogConfig } from '../../modules/@tih/dialog/dialog.config';
import { DialogService } from '../../modules/@tih/dialog/services/dialog.service';
import { take } from 'rxjs/operators';
import { Utilities } from '../../modules/@tih/helpers/utilities';
import { CaptureAdditionalDamageComponent } from '../../modules/@tih/budget/components/capture-aditional-damage/capture-additional-damage.component';
import { StepValidationService } from '../services/step-validation.service';
import { GtmDataLayerService } from '../../modules/@tih/gtm-datalayer/gtm-data-layer.service';
import { Events } from '../../modules/@tih/gtm-datalayer/enums/events.enum';
import { StepNames } from '../../modules/@tih/gtm-datalayer/enums/step-names.enum';
import { Names } from '../../modules/@tih/gtm-datalayer/enums/names.enum';

@Component({
  selector: 'tih-step2-view',
  templateUrl: './step2-view.component.html',
  styleUrls: ['./step2-view.component.scss'],
  providers: [CameraService, DialogConfig]
})
export class Step2ViewComponent {
  constructor(
    private cameraService: CameraService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private stepValidationService: StepValidationService,
    private dataLayerService: GtmDataLayerService,
    public inspectionService: VehicleInspectionService
  ) {}

  saveStepProgress(): void {
    this.inspectionService.saveActiveInspection();
  }

  openWindscreenDialog(index = 0, isEdit = false, additionalDamage = false): void {
    const dialogConfig = {
      data: {
        icon: 'icon-windscreen',
        title: 'Windscreen damage details',
        text: ''
      },
      displayType: CaptureWindscreenDamageComponent
    };

    this.dialogService.open(dialogConfig).then((service) => {
      service.view.pipe(take(1)).subscribe((component) => {
        component.instance.backClick$.subscribe(() => {
          this.dialogService.close();
        });

        const windscreen = Utilities.deepCopy(
          this.inspectionService.activeInspection.vehicle.windscreenDamage[index] || {}
        );

        if (additionalDamage) {
          windscreen.hasAdditionalWindscreenDamage = additionalDamage;
        } else {
          windscreen.hasWindscreenDamage = this.inspectionService.activeInspection.vehicle.hasWindscreenDamages;
        }
        component.instance.windscreenDamage$.next(windscreen);

        component.instance.completeClick$.subscribe(() => {
          if (!component.instance.windscreenDamage.hasWindscreenDamage) {
            this.dialogService.close();
          }

          if (
            (!isEdit && component.instance.windscreenDamage.hasWindscreenDamage) ||
            (component.instance.windscreenDamage.hasWindscreenDamage && additionalDamage)
          ) {
            this.inspectionService.activeInspection.vehicle.windscreenDamage =
              this.inspectionService.activeInspection.vehicle.windscreenDamage || [];
            this.inspectionService.activeInspection.vehicle.windscreenDamage.push({
              photo: component.instance.windscreenDamage.photo,
              area: component.instance.windscreenDamage.area,
              type: component.instance.windscreenDamage.type,
              subType: component.instance.windscreenDamage.subType
            });
          } else {
            if (!component.instance.windscreenDamage.hasWindscreenDamage && !additionalDamage) {
              this.inspectionService.activeInspection.vehicle.windscreenDamage.splice(index, 1);
            } else {
              console.log('This is unused.');

              // FIXME: (Kane Mooi) Not sure what must be done here
              // if ((index !== null || index !== undefined) && !additionalDamage) {
              //   let { photo, area, type, subType } =
              //     this.inspectionService.activeInspection.vehicle.windscreenDamage[index];
              //   photo = component.instance.windscreenDamage.photo;
              //   area = component.instance.windscreenDamage.area;
              //   type = component.instance.windscreenDamage.type;
              //   subType = component.instance.windscreenDamage.subType;
              // }
            }
          }

          this.inspectionService.activeInspection.vehicle.hasWindscreenDamages =
            component.instance.windscreenDamage.hasWindscreenDamage ||
            this.inspectionService.activeInspection.vehicle.windscreenDamage.length > 0;

          this.saveStepProgress();

          this.dialogService.close();

          if (component.instance.windscreenDamage.hasWindscreenDamage) {
            this.openWindscreenDialog(null, false, true);
          }
        });
      });
    });
  }

  // FIXME: (Kane Mooi) This might be the reason for the edit bug
  openDamageDialog(_isEdit = false): void {
    const dialogConfig = {
      data: {
        icon: 'icon-damage',
        title: 'Damage details',
        text: ''
      },
      displayType: CaptureAdditionalDamageComponent
    };

    this.dialogService.open(dialogConfig).then((dialogInstance) => {
      dialogInstance.view.pipe(take(1)).subscribe((dialogComponent) => {
        dialogComponent.instance.backClick$.subscribe(() => {
          this.dialogService.close();
        });

        const vehicleDamages = {
          hasDamages: this.inspectionService.activeInspection.vehicle.hasDamages,
          photos: Utilities.deepCopy(this.inspectionService.activeInspection.vehicle.damages || {})
        };

        dialogComponent.instance.additionalDamage$.next(vehicleDamages);

        dialogComponent.instance.confirmClick$.subscribe((additionalDamages) => {
          this.inspectionService.activeInspection.vehicle.hasDamages = additionalDamages.hasAdditionalDamage;

          if (additionalDamages.hasAdditionalDamage) {
            const cameraConfig = {
              overlayType: CameraOverlayComponent,
              data: {
                label: 'Damage photos',
                allowImageStack: true
              }
            };

            this.cameraService.open(cameraConfig).then((cameraInstance) => {
              cameraInstance.overlay.pipe(take(1)).subscribe((overlayComponent) => {
                overlayComponent.instance.doneClick$.pipe(take(1)).subscribe(() => {
                  this.inspectionService.activeInspection.vehicle.damages = Utilities.deepCopy(
                    overlayComponent.instance.itemList
                  );

                  this.saveStepProgress();
                  this.dialogService.close();
                  cameraInstance.camera.destroy();
                  overlayComponent.destroy();
                });

                overlayComponent.instance.itemList$.next(Utilities.deepCopy(additionalDamages.additionalDamageTypes));
                cameraInstance.camera.instance.cameraResult.subscribe((value) => {
                  overlayComponent.instance.setActiveImage({ dataUri: value?.dataUri });
                });
              });
            });
          } else {
            this.inspectionService.activeInspection.vehicle.damages = [];
            this.dialogService.close();
            this.saveStepProgress();
          }
        });
      });
    });
  }

  inspectionPhotosInit(): Array<{ location: string; label: string; icon: string; suggestionImage: string }> {
    return [
      {
        location: 'Front',
        label: 'Front',
        icon: 'icon-vehicle-front',
        suggestionImage: '../../../assets/images/suggestions/Front.jpg'
      },
      {
        location: 'Back',
        label: 'Back',
        icon: 'icon-vehicle-back',
        suggestionImage: '../../../assets/images/suggestions/Back.jpg'
      },
      {
        location: 'Left',
        label: 'Left side',
        icon: 'icon-vehicle-left',
        suggestionImage: '../../../assets/images/suggestions/Left-side.jpg'
      },
      {
        location: 'Right',
        label: 'Right side',
        icon: 'icon-vehicle-right',
        suggestionImage: '../../../assets/images/suggestions/Right-side.jpg'
      },
      {
        location: 'Odometer',
        label: 'Odometer',
        icon: 'icon-odometer',
        suggestionImage: '../../../assets/images/suggestions/Odometer.jpg'
      },
      {
        location: 'Windscreen',
        label: 'Windscreen',
        icon: 'icon-windscreen',
        suggestionImage: '../../../assets/images/suggestions/Windscreen.jpg'
      }
    ];
  }

  openCamera(inspectionPhotos?, label?: string): void {
    if (inspectionPhotos.length < 1) {
      inspectionPhotos = this.inspectionPhotosInit();
    }

    const cameraConfig = {
      overlayType: CameraOverlayComponent,
      data: {
        label: label
      }
    };

    this.cameraService.open(cameraConfig).then((service) => {
      service.overlay.pipe(take(1)).subscribe((component) => {
        component.instance.doneClick$.pipe(take(1)).subscribe(() => {
          this.inspectionService.activeInspection.vehicle.photos = Utilities.deepCopy(component.instance.itemList);
          this.saveStepProgress();
          service.camera.destroy();
          component.destroy();
        });

        component.instance.itemList$.next(Utilities.deepCopy(inspectionPhotos));
        service.camera.instance.cameraResult.subscribe((value) => {
          component.instance.setActiveImage({ dataUri: value?.dataUri });
        });
      });
    });
  }

  // TODO: (Kane Mooi) Make this common as it is used in step1-view as well
  flattenImages(photos: Array<any>) {
    const retArray = photos?.map((val) => {
      const images = val.images.map((image) => ({ ...image, label: val.label }));
      return images;
    });

    return [].concat(...retArray);
  }

  flattenWindscreenDamage(windscreenDamages: Array<any>) {
    return windscreenDamages
      ?.map((val, index) => [
        { label: `${val.photo.name} ${index + 1}`, dataUri: val.photo.dataUri, index: index },
        { dataUri: val.area.dataUri, index: index }
      ])
      .flat();
  }

  isValid(): boolean {
    return this.stepValidationService.isStepValid(2, this.inspectionService.activeInspection);
  }

  stepBack(): void {
    this.router.navigate(['./step-1'], { relativeTo: this.route.parent });
  }

  stepNext(): void {
    this.inspectionService.activeInspection.step = 3;
    this.saveStepProgress();
    this.router.navigate(['./step-3'], { relativeTo: this.route.parent });

    this.dataLayerService.dataLayerInit(
      Events.FormSuccessSubmit,
      this.inspectionService.activeInspection.policyNumber,
      Names.Inspections,
      StepNames.SelfInspectionStep2
    );

    this.dataLayerService.pushToDataLayer();
  }

  setAdditionalDamageComments(target: EventTarget): void {
    const value = (target as HTMLTextAreaElement).value;
    this.inspectionService.activeInspection.vehicle.additionalDamageComments = value;
  }
}
