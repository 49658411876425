export enum StepNames {
  UpdatePersonalDetails = 'updated personal details',
  SubmittedClaimVehicle = 'submitted a Vehicle claim',
  SubmittedClaimHomeContents = 'submitted a Home Contents claim',
  SubmittedClaimPortables = 'submitted a Portable Possessions claim',
  SubmittedClaimBuilding = 'submitted a Building claim',
  SubmittedSelfInspection = 'submitted a self inspection',
  AddNewHouseholdMember = 'added a new household member',
  AddLiabilityCover = 'added liability cover',
  AddHailCover = 'added hail cover',
  AddSoundCover = 'added sound cover',
  UpdateVehicleSpecifications = 'updated vehicle specifications',
  UploadTrackingCert = 'uploaded Tracking Certificate',
  UploadInspectionCert = 'Inspection Certificate',
  DownloadTrackingCert = 'downloaded Tracking Certificate',
  DownloadInspectionCert = 'Inspection Certificate',
  UpdateVehicleExcessInsuredValue = 'updated vehicle excess insured value',
  UpdateHomeContentsInsuredValue = 'updated home contents insured value',
  UpdatePortablesInsuredValue = 'updated portable possession insured value',
  RequestTaxLetter = 'requested a Tax Letter',
  RequestCoverSummary = 'Summary of cover',
  DownloadPolicySchedule = 'downloaded policy schedule',
  CallBackAccountDetails = 'call back requested for account details update',
  CallBackCellCover = 'call back requested for cellphone cover',
  CallBackBuildingCover = 'call back requested for buildings cover',
  UpdatePaymentDate = 'updated payment date',
  StartSelfInspection = 'started self inspection',
  SelfInspectionStep1 = 'completed self inspection step 1',
  SelfInspectionStep2 = 'completed self inspection step 2',
  SelfInspectionStep3 = 'completed self inspection step 3',
  CompleteSelfInspection = 'completed self inspection',
  WhatsAppOn = 'whatsapp on',
  WhatsAppOff = 'whatsapp off'
}
