import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  OnDestroy
} from '@angular/core';
import { ToastViewComponent } from '../toast-view/toast-view.component';
import { ToastConfig } from '../config/toast.config';
import { GenericInjector } from '../../helpers/generic.injector';

@Injectable()
export class ToastService implements OnDestroy {
  private toastComponentRef: ComponentRef<ToastViewComponent>;
  private config: ToastConfig;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  displayToast(config: ToastConfig): void {
    this.config = config;
    this.appendToastComponentToBody();
  }

  private appendToastComponentToBody() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ToastViewComponent);

    const map = new WeakMap();
    map.set(ToastConfig, this.config);

    const componentRef = componentFactory.create(new GenericInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<ToastViewComponent>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.toastComponentRef = componentRef;

    this.toastOnInit();
  }

  private toastOnInit() {
    this.toastComponentRef.instance.ngOnInit().then(() => {
      this.toastComponentRef.instance.removeToastSubj.subscribe((value) => {
        if (value) {
          this.removeToastComponentFromBody();
        }
      });
    });
  }

  private removeToastComponentFromBody() {
    this.appRef.detachView(this.toastComponentRef.hostView);
    this.toastComponentRef.destroy();
  }

  ngOnDestroy() {
    this.toastComponentRef?.instance?.removeToastSubj.next(true);
  }
}
