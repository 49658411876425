// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capture-survey-response-view {
  display: flex;
  flex-direction: column;
}
.capture-survey-response-view .capture-survey-response-view__icon-group {
  position: relative;
}
.capture-survey-response-view .capture-survey-response-view__icon-group .icon-group__background {
  color: var(--main-1);
  position: relative;
}
.capture-survey-response-view .capture-survey-response-view__text {
  color: var(--sub-6);
}
.capture-survey-response-view .capture-survey-response-view__sub-text {
  color: var(--sub-6);
}
.capture-survey-response-view .capture-survey-response-view__input-label {
  color: var(--sub-8);
  font-weight: 600;
}
.capture-survey-response-view .capture-survey-response-view__input-container {
  border: 1px solid var(--sub-4);
}
.capture-survey-response-view .capture-survey-response-view__input-container .input-container__input {
  border: none;
  width: 100%;
  outline: none;
  color: var(--sub-6);
  font-weight: 600;
}
.capture-survey-response-view .capture-survey-response-view__input-container .input-container__input::placeholder {
  color: var(--sub-5);
  font-weight: normal;
}
.capture-survey-response-view .capture-survey-response-view__input-container .input-container__input::-webkit-input-placeholder {
  color: var(--sub-5);
  font-weight: normal;
}
.capture-survey-response-view .capture-survey-response-view__input-container .input-container__select {
  border: none;
  width: 100%;
  outline: none;
  color: var(--sub-6);
  font-weight: 600;
  background-color: white;
}
.capture-survey-response-view .capture-survey-response-view__buttons-container {
  justify-content: space-between;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
