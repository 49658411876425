import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigurationService {
  private configuration: IServerConfiguration;

  constructor(private http: HttpClient) {}

  loadConfig() {
    const appConfig = localStorage.getItem('appConfig');
    if (appConfig) {
      this.configuration = JSON.parse(appConfig);
    }

    return this.http
      .get<IServerConfiguration>('/Configuration/ConfigurationData')
      .toPromise()
      .then(
        (config: IServerConfiguration) => {
          this.configuration = <IServerConfiguration>config;
          localStorage.setItem('appConfig', JSON.stringify(this.configuration));
        },
        (error: unknown) => console.error(error)
      );
  }

  get baseBudgetUrl(): string {
    return this.configuration?.BaseBudgetUrl;
  }

  get gtmIds() {
    return { gtmId: this.configuration?.GtmId, gtmId360: this.configuration?.GtmId360 };
  }
}

export interface IServerConfiguration {
  BaseBudgetUrl: string;
  GtmId?: string;
  GtmId360?: string;
}
