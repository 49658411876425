import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraService } from './services/camera.service';
import { OverlayComponentDirective } from './directives/overlay-component.directive';
import { CameraViewComponent } from './view-components/camera.-view.component/camera-view.component';
import { CameraOutputComponent } from './components/camera-output.component/camera-output.component';
import { CameraControlsComponent } from './components/camera-controls.component/camera-controls.component';
import { BarcodeScannerComponent } from './components/barcode-scanner.component/barcode-scanner.component';
import { CameraCaptureButtonComponent } from './components/camera-capture-button.component/camera-capture-button.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    BarcodeScannerComponent,
    CameraViewComponent,
    CameraOutputComponent,
    CameraControlsComponent,
    CameraCaptureButtonComponent,
    OverlayComponentDirective
  ],
  providers: [CameraService]
})
export class CameraModule {}
