// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  width: auto;
}
.card .spacing {
  border: solid 1px;
  flex-direction: column;
  border: 0.5px solid var(--sub-4);
  box-shadow: 1px 3px 7px 0px var(--sub-4);
  background-color: var(--sub-1);
  font-size: var(--text-sm);
  flex: 1;
  display: flex;
}
.card .spacing :before {
  font-size: 30px;
  padding-right: 10px;
}
.card .spacing .btn-spacing {
  display: flex;
}
.card .spacing .car-desc {
  display: flex;
  align-items: center;
}
.card .spacing .btn-long {
  flex: 0.35;
}
.card .no-vehicles-text {
  color: var(--sub-8);
  text-align: center;
}

.page-header-component {
  background-color: var(--sub-1);
  box-shadow: 0 0 23px 0 rgba(153, 153, 153, 0.2);
  z-index: 1;
  position: relative;
}

.page-sub-header-component {
  border-bottom: 0.1px solid var(--sub-5);
}
.page-sub-header-component .page-sub-header-component__description {
  color: var(--sub-5);
}

.step-component {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.step-component .step-component__step {
  padding: 10px;
  text-align: center;
  width: 100%;
  background-color: white;
  border: 1px solid var(--sub-4);
}
.step-component .step-component__step.step--current {
  background-color: var(--main-1);
  color: var(--sub-1);
}
.step-component .step-component__step:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.step-component .step-component__step:last-child {
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.circle-loader {
  width: 15px;
  height: 15px;
}

.hidden-binding {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
