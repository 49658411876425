import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastViewComponent } from './toast-view/toast-view.component';
import { ToastService } from './services/toast.service';

@NgModule({
  declarations: [ToastViewComponent],
  imports: [CommonModule],
  providers: [ToastService]
})
export class ToastModule {}
