import { Component, Input } from '@angular/core';

@Component({
  selector: 'tih-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent {
  @Input() theme?: string;
  @Input() isInline = false;
}
