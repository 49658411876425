export enum ConnectionType {
  bluetooth = 'bluetooth',
  cellular = 'cellular',
  ethernet = 'ethernet',
  none = 'none',
  wifi = 'wifi',
  wimax = 'wimax',
  other = 'other',
  unknown = 'unknown'
}
