export enum Names {
  Vehicle = 'vehicle',
  HouseHold = 'household',
  Personal = 'personal information',
  Insurance = 'my insurance',
  Claims = 'my claims',
  Documents = 'my documents',
  Payments = 'my payments',
  Inspections = 'self inspection'
}
