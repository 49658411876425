import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CameraConfig } from '../../camera.config';

@Component({
  selector: 'tih-camera-output',
  templateUrl: './camera-output.component.html',
  styleUrls: ['./camera-output.component.scss']
})
export class CameraOutputComponent implements OnInit, OnDestroy {
  @Output() photoResult = new EventEmitter();

  @ViewChild('photoSnapshot', { static: true })
  private photoSnapshot: ElementRef<HTMLCanvasElement>;

  @ViewChild('cameraFeed', { static: true })
  cameraFeed: ElementRef<HTMLVideoElement>;

  @Output() videoTrack$ = new EventEmitter<MediaStreamTrack>();

  private videoTracks$ = new BehaviorSubject<MediaStreamTrack[]>([]);

  constructor(public cameraConfig: CameraConfig) {
    this.videoTracks$.subscribe((tracks: MediaStreamTrack[]) => {
      this.videoTrack$.next(tracks[0]);
    });
  }

  async ngOnInit() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: { exact: 'environment' } } })
        .then((stream: MediaStream) => {
          this.cameraFeed.nativeElement.srcObject = stream;
          this.cameraFeed.nativeElement.play();
          this.videoTracks$.next(stream.getVideoTracks());
        })
        .catch((error: unknown) => {
          console.error(error);
          // Fallback to a looser constraint
          navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then((stream) => {
            this.cameraFeed.nativeElement.srcObject = stream;
            this.cameraFeed.nativeElement.play();
            this.videoTracks$.next(stream.getVideoTracks());
          });
        });
    }
  }

  ngOnDestroy() {
    this.videoTracks$.subscribe((tracks: MediaStreamTrack[]) => {
      tracks.forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    });
  }

  capturePhoto(): void {
    this.photoSnapshot.nativeElement.width =
      this.cameraFeed.nativeElement.videoWidth == 0 ? 320 : this.cameraFeed.nativeElement.videoWidth;
    this.photoSnapshot.nativeElement.height =
      this.cameraFeed.nativeElement.videoHeight == 0 ? 320 : this.cameraFeed.nativeElement.videoHeight;

    const xOffset = (this.photoSnapshot.nativeElement.width - this.cameraFeed.nativeElement.videoWidth) / 2;
    const { width, height } = this.photoSnapshot.nativeElement;
    this.photoSnapshot.nativeElement
      .getContext('2d')
      .drawImage(this.cameraFeed.nativeElement, xOffset, 0, width, height);

    this.photoResult.emit({
      dataUri: this.photoSnapshot.nativeElement.toDataURL()
    });
  }
}
