import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import Dynamsoft from '../../../../../libraries/dynamsoft-barcode-reader/dynamsoft';
import { BarcodeScanner } from 'dynamsoft-javascript-barcode';

@Component({
  selector: 'tih-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {
  private destroyScanner = false;
  private scanner: BarcodeScanner = null;

  @ViewChild('scanSnapshot', { static: true })
  private scanSnapshot: ElementRef<HTMLCanvasElement>;

  @Output() barcodeResult$ = new EventEmitter();
  @Output() videoTrack$ = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  private calculateSize(srcSize: Size, dstSize: Size) {
    const srcRatio = srcSize.width / srcSize.height;
    const dstRatio = dstSize.width / dstSize.height;
    if (dstRatio > srcRatio) {
      return {
        width: dstSize.height * srcRatio,
        height: dstSize.height
      };
    } else {
      return {
        width: dstSize.width,
        height: dstSize.width / srcRatio
      };
    }
  }

  async ngOnInit() {
    try {
      this.scanner = this.scanner || (await Dynamsoft.BarcodeScanner.createInstance());

      if (this.destroyScanner) {
        this.scanner.destroy();
        return;
      }

      await this.scanner.setUIElement(this.elementRef.nativeElement);
      this.scanner.onFrameRead = (results) => {
        if (results.length) {
          this.scanSnapshot.nativeElement.width = this.scanner._video.videoWidth;
          this.scanSnapshot.nativeElement.height = this.scanner._video.videoHeight;

          const renderSize = this.calculateSize(
            {
              width: this.scanner._video.videoWidth,
              height: this.scanner._video.videoHeight
            },
            {
              width: this.scanSnapshot.nativeElement.width,
              height: this.scanSnapshot.nativeElement.height
            }
          );

          const xOffset = (this.scanSnapshot.nativeElement.width - renderSize.width) / 2;

          this.scanSnapshot.nativeElement
            .getContext('2d')
            .drawImage(this.scanner._video, xOffset, 0, renderSize.width, renderSize.height);

          this.barcodeResult$.emit({
            result: results[0],
            dataUri: this.scanSnapshot.nativeElement.toDataURL()
          });
        }
      };

      await this.scanner.open().then(() => {
        this.videoTrack$.emit(this.scanner._videoTrack);
      });

      if (this.destroyScanner) {
        this.scanner.destroy();
        return;
      }
    } catch (ex) {
      console.error(ex);
      this.barcodeResult$.emit(ex.message);
    }
  }

  capturePhoto(): void {
    this.scanSnapshot.nativeElement.width = this.scanner._video.videoWidth;
    this.scanSnapshot.nativeElement.height = this.scanner._video.videoHeight;

    const xOffset = (this.scanSnapshot.nativeElement.width - this.scanner._video.videoWidth) / 2;
    const { width, height } = this.scanSnapshot.nativeElement;
    this.scanSnapshot.nativeElement.getContext('2d').drawImage(this.scanner._video, xOffset, 0, width, height);

    this.barcodeResult$.emit({
      dataUri: this.scanSnapshot.nativeElement.toDataURL()
    });
  }

  ngOnDestroy(): void {
    this.destroyScanner = true;
    if (this.scanner) {
      this.scanner.destroy();
    }
  }
}

type Size = { height: number; width: number };
