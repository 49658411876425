import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[tihDigitsOnly]'
})
export class DigitsOnlyDirective {
  readonly ALLOWED_KEYS = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];

  readonly ALLOWED_COMMANDS = ['a', 'c', 'v', 'x'];

  inputElement: HTMLInputElement;

  constructor(elementRef: ElementRef) {
    this.inputElement = elementRef.nativeElement;
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    if (
      this.ALLOWED_KEYS.indexOf(e.key) > -1 ||
      (this.ALLOWED_COMMANDS.indexOf(e.key) > -1 && (e.ctrlKey === true || e.metaKey === true))
    ) {
      return;
    }

    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\D/g, ''); // delete all non digits

    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();

    const textData = event.dataTransfer.getData('text').replace(/\D/g, '');

    this.inputElement.focus();

    document.execCommand('insertText', false, textData);
  }
}
