import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CameraConfig } from '../../../camera/camera.config';

@Component({
  selector: 'tih-camera-overlay',
  templateUrl: './camera-overlay.component.html',
  styleUrls: ['./camera-overlay.component.scss']
})
export class CameraOverlayComponent {
  itemList: Array<any> = [];
  itemList$: Subject<any> = new Subject<any>();

  @Output() doneClick$ = new EventEmitter<void>();
  @Output() itemClick$ = new EventEmitter();

  activeItem: any;

  constructor(public config: CameraConfig) {
    this.itemList$.subscribe((value) => {
      value.forEach((item) => {
        if (!(item.images?.length > 0) && !this.activeItem) {
          this.itemClick(item);
        } else {
          item.active = false;
        }
      });

      this.itemList = value;
    });
  }

  getBackgroundImage(item): string {
    const latestImage = item?.images?.slice(-1);
    if (latestImage?.[0]?.dataUri) {
      return `url(${latestImage?.[0]?.dataUri})`;
    }

    return '';
  }

  itemClick(item): void {
    if (this.activeItem) {
      this.activeItem.active = false;
    }

    item.active = true;
    item.images = item.images || [];
    this.activeItem = item;
    this.itemClick$.emit(item);
  }

  setActiveImage(image): void {
    if (this.config?.data?.allowImageStack) {
      this.activeItem.images.push(image);
    } else {
      this.activeItem.images = [image];
    }
  }

  doneClick(): void {
    this.doneClick$.emit();
  }

  isListEmpty(): boolean {
    const emptyList = this.itemList.filter((item) => {
      return !(item?.images?.length > 0);
    });

    return emptyList.length > 0;
  }
}
