// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas {
  text-align: center;
  width: 100%;
  position: relative;
}
.canvas .canvas-refresh {
  border: none;
  background: none;
  position: absolute;
  left: -10px;
  top: 10px;
  font-size: 1.2em;
  color: var(--main-1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
