// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
.spinner-loader {
  text-align: center;
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
}
.spinner-loader.inline {
  position: relative;
  min-height: 300px;
  z-index: 1;
}
.spinner-loader.m1 {
  background-color: rgba(255, 255, 255, 0.5);
}
.spinner-loader.m1 .spinner div {
  background: var(--main-1);
}
.spinner-loader .background-overlay-center {
  display: table-cell;
  vertical-align: middle;
}
.spinner-loader .spinner {
  position: relative;
  width: 120px;
  height: 120px;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
}
.spinner-loader .spinner div {
  width: 6%;
  height: 16%;
  background: #fff;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: fade 1s linear infinite;
  -webkit-border-radius: 50px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-animation: fade 1s linear infinite;
}
.spinner-loader .spinner div.bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
  -webkit-transform: rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}
.spinner-loader .spinner div.bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -0.9167s;
  -webkit-transform: rotate(30deg) translate(0, -130%);
  -webkit-animation-delay: -0.9167s;
}
.spinner-loader .spinner div.bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -0.833s;
  -webkit-transform: rotate(60deg) translate(0, -130%);
  -webkit-animation-delay: -0.833s;
}
.spinner-loader .spinner div.bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.7497s;
  -webkit-transform: rotate(90deg) translate(0, -130%);
  -webkit-animation-delay: -0.7497s;
}
.spinner-loader .spinner div.bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.667s;
  -webkit-transform: rotate(120deg) translate(0, -130%);
  -webkit-animation-delay: -0.667s;
}
.spinner-loader .spinner div.bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.5837s;
  -webkit-transform: rotate(150deg) translate(0, -130%);
  -webkit-animation-delay: -0.5837s;
}
.spinner-loader .spinner div.bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.5s;
  -webkit-transform: rotate(180deg) translate(0, -130%);
  -webkit-animation-delay: -0.5s;
}
.spinner-loader .spinner div.bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.4167s;
  -webkit-transform: rotate(210deg) translate(0, -130%);
  -webkit-animation-delay: -0.4167s;
}
.spinner-loader .spinner div.bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.333s;
  -webkit-transform: rotate(240deg) translate(0, -130%);
  -webkit-animation-delay: -0.333s;
}
.spinner-loader .spinner div.bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.2497s;
  -webkit-transform: rotate(270deg) translate(0, -130%);
  -webkit-animation-delay: -0.2497s;
}
.spinner-loader .spinner div.bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.167s;
  -webkit-transform: rotate(300deg) translate(0, -130%);
  -webkit-animation-delay: -0.167s;
}
.spinner-loader .spinner div.bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.0833s;
  -webkit-transform: rotate(330deg) translate(0, -130%);
  -webkit-animation-delay: -0.0833s;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.circle-loader {
  border: 2px solid var(--sub-3);
  border-radius: 50%;
  border-top: 2px solid var(--main-1);
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  display: inline-block;
  position: absolute;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
