// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-item-view {
  display: flex;
  flex-direction: column;
  border: 0.5px solid var(--sub-4);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--sub-1);
  font-size: var(--text-sm);
  flex: 1;
}
.toggle-item-view .toggle-item-view__item {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: var(--sub-1);
  font-size: var(--text-sm);
  flex: 1;
}
.toggle-item-view .toggle-item-view__item .item__icon {
  margin-left: 5px;
  display: flex;
  color: var(--sub-6);
}
.toggle-item-view .toggle-item-view__item .item__icon.success {
  color: var(--main-4);
}
.toggle-item-view .toggle-item-view__item .item__label {
  flex: auto;
  padding: 10px;
}
.toggle-item-view .toggle-item-view__item .item__action {
  margin-right: 5px;
}
.toggle-item-view .toggle-item-view__item .item__action .action {
  border-radius: 50%;
  justify-self: right;
  height: 28px;
  width: 28px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sub-1);
}
.toggle-item-view .toggle-item-view__item .item__action .action.action--add {
  background-color: var(--main-1);
}
.toggle-item-view .toggle-item-view__item .item__action .action.action--add:before {
  font-size: var(--text-md);
}
.toggle-item-view .toggle-item-view__item .item__action .action.action--toggle {
  background-color: var(--sub-4);
}
.toggle-item-view .toggle-item-view__item .item__action .action.action--toggle:before {
  transition: 0.5s transform ease-in-out;
  font-size: var(--text-md);
}
.toggle-item-view .toggle-item-view__item .item__action .action.action--toggle.action--toggle--open:before {
  transform: rotateX(180deg);
}
.toggle-item-view .toggle-item-view__content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  height: 0;
  transition: 0.5s height ease-in-out;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
