import { Injectable } from '@angular/core';
import { DatabaseContext } from '../../modules/@tih/budget/database-context/database.context';
import { HttpClientService } from '../../modules/@tih/httpclient/http-client.service';
import { UserDetails } from '../../modules/@tih/budget/database-context/tables/user-details.model';
import { BehaviorSubject } from 'rxjs';
import { PwaService } from '../../modules/@tih/pwa/pwa.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  activeUser: UserDetails;
  private activeUser$ = new BehaviorSubject<UserDetails>(null);

  constructor(
    private dbContext: DatabaseContext,
    private httpClient: HttpClientService,
    private pwaService: PwaService
  ) {
    this.activeUser$.subscribe((user) => {
      this.activeUser = user;
    });
  }

  private getUserFromApi() {
    return this.httpClient.getSelf<UserDetails>(`user/GetUserProfileDetails`);
  }

  private addUser(userDetails: UserDetails) {
    this.dbContext.userDetails.put(userDetails);
    if (userDetails.policyHolderIdNumber) {
      localStorage.setItem('activeUserId', userDetails.policyHolderIdNumber);
    } else {
      this.logoutActiveUser();
    }
  }

  private setUserFromApi() {
    this.getUserFromApi().subscribe((apiUser) => {
      this.addUser(apiUser);
      this.activeUser$.next(apiUser);
    });
  }

  public setActiveUser() {
    const activeUserId = localStorage.getItem('activeUserId');
    if (activeUserId) {
      this.dbContext.userDetails.get(activeUserId, (user) => {
        if (user) {
          this.activeUser$.next(user);
        }
        this.setUserFromApi();
      });
    } else {
      this.setUserFromApi();
    }
  }

  public removeLocalUser() {
    this.pwaService.unregisterServiceWorker();
    localStorage.removeItem('activeUserId');
  }

  public logoutActiveUser() {
    this.removeLocalUser();
    window.location.replace('/login/deregister');
  }
}
