import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  OnDestroy
} from '@angular/core';
import { DialogComponent } from '../dialog.component/dialog.component';
import { DialogConfig } from '../dialog.config';
import { Subject } from 'rxjs';
import { GenericInjector } from '../../helpers/generic.injector';

@Injectable()
export class DialogService implements OnDestroy {
  private dialogComponentRef: ComponentRef<DialogComponent>;
  private displayComponentRef: Subject<ComponentRef<any>> = new Subject<ComponentRef<any>>();

  private config: DialogConfig = new DialogConfig();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  private componentOnInit(): void {
    this.dialogComponentRef.instance.ngOnInit().then(() => {
      this.dialogComponentRef.instance.closeDialog.subscribe((value: boolean) => {
        if (value) {
          this.close();
        }
      });

      this.dialogComponentRef.instance.displayComponentRef.subscribe((value) => {
        this.displayComponentRef.next(value);
      });
    });
  }

  private appendDialogComponentToBody(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);

    const map = new WeakMap();
    map.set(DialogConfig, this.config);

    const componentRef = componentFactory.create(new GenericInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;
    this.componentOnInit();
  }

  private removeDialogComponentFromBody(): void {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

  open(config?: DialogConfig): Promise<any>;
  async open(config?: DialogConfig): Promise<any> {
    this.config = config;
    this.appendDialogComponentToBody();
    this.dialogComponentRef.instance.displayComponentType = config.displayType;

    return new Promise((resolve) => {
      resolve({ dialog: this.dialogComponentRef, view: this.displayComponentRef });
    });
  }

  close(): void {
    this.removeDialogComponentFromBody();
  }

  ngOnDestroy(): void {
    this.config = new DialogConfig();
    this.dialogComponentRef?.destroy();
    this.displayComponentRef.unsubscribe();
  }
}
