import { Component, Input } from '@angular/core';

@Component({
  selector: 'tih-thank-you-step',
  templateUrl: './thank-you-step.component.html',
  styleUrls: ['./thank-you-step.component.scss']
})
export class SurveyThankYouStepComponent {
  @Input() currentStep: number;
  @Input() activeUserName: string;
}
