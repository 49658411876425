import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraOverlayComponent } from './components/camera-overlay.component/camera-overlay.component';
import { ToggleItemComponent } from './components/toggle-item.component/toggle-item.component';
import { ButtonComponent } from './components/button.component/button.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { CaptureOdometerReadingComponent } from './components/capture-odometer-reading/capture-odometer-reading.component';
import { CaptureSurveyResponseComponent } from './components/capture-survey-response/capture-survey-response.component';
import { FormsModule } from '@angular/forms';
import { DigitsOnlyDirective } from './directives/digits-only.directive';
import { AdditionalAccessoriesComponent } from './components/additional-accessories/additional-accessories.component';
import { WindscreenDamageCanvasComponent } from './components/windscreen-damage-canvas/windscreen-damage-canvas.component';
import { HeadingGroupComponent } from './components/heading-group/heading-group.component';
import { CaptureAdditionalDamageComponent } from './components/capture-aditional-damage/capture-additional-damage.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { CaptureWindscreenDamageComponent } from './components/capture-windscreen-damage/capture-windscreen-damage.component';
import { CwdStep1Component } from './components/capture-windscreen-damage/components/cwd-step1/cwd-step1.component';
import { CwdStep2Component } from './components/capture-windscreen-damage/components/cwd-step2/cwd-step2.component';
import { CwdStep3Component } from './components/capture-windscreen-damage/components/cwd-step3/cwd-step3.component';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { SurveyThankYouStepComponent } from './components/capture-survey-response/components/thank-you-step/thank-you-step.component';
import { SurveySubmitStepComponent } from './components/capture-survey-response/components/survey-step/survey-step.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [
    CameraOverlayComponent,
    ToggleItemComponent,
    WindscreenDamageCanvasComponent,
    ButtonComponent,
    HeadingGroupComponent,
    InfoDialogComponent,
    CaptureOdometerReadingComponent,
    CaptureSurveyResponseComponent,
    ButtonComponent,
    DigitsOnlyDirective,
    AdditionalAccessoriesComponent,
    CaptureAdditionalDamageComponent,
    RadioButtonComponent,
    CaptureWindscreenDamageComponent,
    CwdStep1Component,
    CwdStep2Component,
    CwdStep3Component,
    SpinnerLoaderComponent,
    SurveyThankYouStepComponent,
    SurveySubmitStepComponent
  ],
  exports: [
    ToggleItemComponent,
    CameraOverlayComponent,
    ButtonComponent,
    WindscreenDamageCanvasComponent,
    InfoDialogComponent,
    HeadingGroupComponent,
    RadioButtonComponent,
    CaptureAdditionalDamageComponent,
    CaptureWindscreenDamageComponent,
    SpinnerLoaderComponent
  ]
})
export class BudgetModule {}
