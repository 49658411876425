// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cwd-container {
  display: flex;
  flex-direction: column;
}
.cwd-container .align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cwd-container .radio-container {
  width: 100%;
}
.cwd-container .radio-container .radio-input {
  min-width: 33.33%;
  max-width: 33.33%;
}
.cwd-container .radio-container .radio-input-image {
  min-width: 50%;
  max-width: 50%;
}
.cwd-container .radio-container .radio-input-image label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cwd-container .radio-container .radio-input-image label span {
  padding-left: 15px;
}
.cwd-container .text-center {
  text-align: center;
}
.cwd-container .damage-type-display {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
