import { Component } from '@angular/core';
import { PwaConfig } from '../../pwa.config';

@Component({
  selector: 'tih-pwa-installed',
  templateUrl: './pwa-already-installed.component.html',
  styleUrls: ['./pwa-already-installed.component.scss']
})
export class PwaInstalledPromptComponent {
  constructor(public config: PwaConfig) {}
}
