import { Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { VehicleInspectionService } from '../services/vehicle-inspection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../modules/@tih/toast/services/toast.service';
import { ToastConfig } from '../../modules/@tih/toast/config/toast.config';
import { CameraService } from 'src/modules/@tih/camera/services/camera.service';
import { DialogService } from '../../modules/@tih/dialog/services/dialog.service';
import { InfoDialogComponent } from '../../modules/@tih/budget/components/info-dialog/info-dialog.component';
import { CaptureOdometerReadingComponent } from 'src/modules/@tih/budget/components/capture-odometer-reading/capture-odometer-reading.component';
import { StepValidationService } from '../services/step-validation.service';
import { CameraOverlayComponent } from '../../modules/@tih/budget/components/camera-overlay.component/camera-overlay.component';
import { Utilities } from '../../modules/@tih/helpers/utilities';
import { GtmDataLayerService } from '../../modules/@tih/gtm-datalayer/gtm-data-layer.service';
import { Events } from '../../modules/@tih/gtm-datalayer/enums/events.enum';
import { StepNames } from '../../modules/@tih/gtm-datalayer/enums/step-names.enum';
import { Names } from '../../modules/@tih/gtm-datalayer/enums/names.enum';

@Component({
  selector: 'tih-step1-view',
  templateUrl: './step1-view.component.html',
  styleUrls: ['./step1-view.component.scss'],
  providers: [CameraService, ToastService, DialogService]
})
export class Step1ViewComponent {
  constructor(
    private cameraService: CameraService,
    public inspectionService: VehicleInspectionService,
    public dialogService: DialogService,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private stepValidationService: StepValidationService,
    private dataLayerService: GtmDataLayerService
  ) {}

  saveStepProgress(): void {
    if (this.inspectionService.activeInspection.status === 'Not started') {
      this.inspectionService.activeInspection.status = 'Incomplete';
    }
    this.inspectionService.saveActiveInspection();
  }

  openCamera(cameraObj, label): void {
    if (cameraObj.length < 1) {
      cameraObj = [
        {
          location: 'front',
          label: 'Front',
          showLabel: true,
          icon: 'icon-license-front'
        },
        {
          location: 'back',
          label: 'Back',
          showLabel: true,
          icon: 'icon-license-back'
        }
      ];
    }

    const cameraConfig = {
      data: {
        label: label
      },
      overlayType: CameraOverlayComponent
    };

    this.cameraService.open(cameraConfig).then((service) => {
      service.overlay.pipe(take(1)).subscribe((component) => {
        component.instance.doneClick$.pipe(take(1)).subscribe(() => {
          this.inspectionService.activeInspection.driver.licenseCard = Utilities.deepCopy(component.instance.itemList);
          this.saveStepProgress();
          service.camera.destroy();
          component.destroy();
        });

        component.instance.itemList$.next(Utilities.deepCopy(cameraObj));
        service.camera.instance.cameraResult.subscribe((value) => {
          component.instance.setActiveImage({ dataUri: value?.dataUri });
        });
      });
    });
  }

  openScanner(scannerObj, label, isDiskScan: boolean): void {
    const cameraConfig = {
      data: {
        isBarcodeScanner: true,
        autoClose: true,
        label: label
      }
    };

    this.cameraService.open(cameraConfig).then((service) => {
      const toastConfig = new ToastConfig(
        `Scanning ${isDiskScan ? 'the barcode' : "driver's license"}, ensure it's visible & aligned.`,
        6
      );

      toastConfig.assetPath = '../../../assets/images/scan/ScanIcon.png';
      this.toastService.displayToast(toastConfig);

      service.camera.instance.cameraResult?.pipe(take(1)).subscribe((response) => {
        scannerObj.scanResult = response?.result?.barcodeText;
        scannerObj.dataUri = response?.dataUri;
        this.saveStepProgress();
      });
    });

    this.cameraService.onAfterClose.subscribe(() => {
      this.toastService.ngOnDestroy();
    });
  }

  private getScanDialogConfig(isDiskScan: boolean) {
    if (isDiskScan) {
      return {
        data: {
          icon: '../../../assets/images/scan/ScanVehicleLicense.svg',
          heading: 'Scan vehicle license disc',
          text: 'Please make sure the barcode on your disc is clearly visible.',
          iconType: 'asset'
        },
        displayType: InfoDialogComponent
      };
    } else {
      return {
        data: {
          icon: '../../../assets/images/scan/ScanDriversLicense.svg',
          heading: `Take a photo of the regular driver's license card`,
          text: 'Please take a photo of the front and back of the license card.',
          iconType: 'asset'
        },
        displayType: InfoDialogComponent
      };
    }
  }

  openScanDialog(scannerObj, label, isDiskScan: boolean): void {
    const dialogConfig = this.getScanDialogConfig(isDiskScan);
    this.dialogService.open(dialogConfig).then((service) => {
      service.view.pipe(take(1)).subscribe((component) => {
        component.instance.completeClick$.subscribe(() => {
          isDiskScan ? this.openScanner(scannerObj, label, isDiskScan) : this.openCamera(scannerObj, label);
          this.dialogService.close();
        });
      });
    });
  }

  openOdometerDialog(): void {
    const dialogConfig = {
      data: {
        icon: 'icon-odometer',
        title: 'Odometer reading'
      },
      displayType: CaptureOdometerReadingComponent
    };

    this.dialogService.open(dialogConfig).then((service) => {
      service.view.pipe(take(1)).subscribe((component) => {
        component.instance.backClick$.subscribe(() => {
          this.dialogService.close();
        });

        component.instance.confirmClick$.subscribe((mileage: number) => {
          this.inspectionService.activeInspection.vehicle.odometer = mileage;
          this.dialogService.close();
          this.saveStepProgress();
        });

        component.instance.setMileage(this.inspectionService.activeInspection?.vehicle?.odometer);
      });
    });
  }

  isValid(): boolean {
    return this.stepValidationService.isStepValid(1, this.inspectionService.activeInspection);
  }

  // TODO: (Kane Mooi) Make this common as it is used in step2-view as well
  flattenImages(photos: Array<any>) {
    const retArray = photos?.map((val) => {
      const images = val.images.map((image) => ({ ...image, label: val.label }));
      return images;
    });

    return [].concat(...retArray);
  }

  stepBack(): void {
    this.router.navigate(['./vehicles'], { relativeTo: this.route.parent });
  }

  stepNext(): void {
    this.inspectionService.activeInspection.step = 2;
    this.saveStepProgress();
    this.router.navigate(['./step-2'], { relativeTo: this.route.parent });
    this.dataLayerService.dataLayerInit(
      Events.FormSuccessSubmit,
      this.inspectionService.activeInspection.policyNumber,
      Names.Inspections,
      StepNames.SelfInspectionStep1
    );
    this.dataLayerService.pushToDataLayer();
  }
}
