import { Component, Input } from '@angular/core';

@Component({
  selector: 'tih-heading-group',
  templateUrl: './heading-group.component.html',
  styleUrls: ['./heading-group.component.scss']
})
export class HeadingGroupComponent {
  @Input() main?: string;
  @Input() sub?: string;
  @Input() error?: boolean;
}
