// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description-component .description-component__heading {
  font-weight: 600;
  color: var(--sub-6);
}
.description-component .description-component__copy {
  color: var(--sub-5);
}

.step-component {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.step-component .step-component__step {
  padding: 10px;
  text-align: center;
  width: 100%;
  background-color: white;
  border: 1px solid var(--sub-4);
}
.step-component .step-component__step.step--current {
  background-color: var(--main-1);
  color: var(--sub-1);
}
.step-component .step-component__step:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.step-component .step-component__step:last-child {
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.toggle-view-container {
  display: flex;
  flex-direction: column;
}

.back-next-component {
  display: flex;
  justify-content: space-between;
}

.button-component {
  border-radius: 100px;
  padding: 10px 15px;
  text-align: center;
  border: none;
  flex: 0.1;
  width: 100%;
  font-size: 16px;
}
.button-component .button-component__icon {
  display: flex;
  justify-content: center;
}
.button-component .button-component__icon:before {
  align-items: center;
  display: flex;
  font-size: 18px;
}
.button-component .button-component__icon.icon--left {
  flex-direction: row;
}
.button-component .button-component__icon.icon--left .button-component__text {
  padding-left: 15px;
}
.button-component .button-component__icon.icon--right {
  flex-direction: row-reverse;
}
.button-component .button-component__icon.icon--right .button-component__text {
  padding-right: 15px;
}
.button-component.m1 {
  background-color: var(--main-1);
  color: var(--sub-1);
}
.button-component.m1:disabled, .button-component.m1.disabled {
  background-color: transparent;
  color: var(--main-1);
  border: 1px solid;
}
.button-component.s5 {
  background-color: var(--sub-5);
  color: var(--sub-1);
}
.button-component.s5:disabled, .button-component.s5.disabled, .button-component.s5.inverse {
  background-color: transparent;
  color: var(--sub-5);
  border: 1px solid;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
