import Dexie from 'dexie';

import { Injectable } from '@angular/core';
import { VehicleInspection } from './tables/vehicle-inspection.model';
import { UserDetails } from './tables/user-details.model';
import { IActiveInspection } from '../interfaces/active-inspection.interface';

@Injectable()
export class DatabaseContext extends Dexie {
  // referenced in stores
  vehicleInspections: Dexie.Table<VehicleInspection, string>;
  userDetails: Dexie.Table<UserDetails, string>;
  activeInspection: Dexie.Table<IActiveInspection>;

  constructor() {
    super('DatabaseContext');
    this.version(1).stores({
      vehicleInspections: 'key',
      userDetails: 'policyHolderIdNumber',
      activeInspection: 'id,inspectionKey'
    });

    this.mapToClass();
  }

  // maps to class for easy use
  private mapToClass() {
    this.vehicleInspections.mapToClass(VehicleInspection);
    this.userDetails.mapToClass(UserDetails);
  }
}
