import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector
} from '@angular/core';
import { BeforeInstallPromptEvent } from './pwa.models';
import { PwaPromptComponent } from './components/pwa-prompt-component/pwa.component';
import { PwaInstalledPromptComponent } from './components/pwa-already-installed-component/pwa-already-installed.component';

import { Subject } from 'rxjs';
import { PwaConfig } from './pwa.config';
import { ConnectionType } from './enums/connection-type.enum';
import { ConnectionEffectiveType } from './enums/connection-effective-type.enum';
import { GenericInjector } from '../helpers/generic.injector';

@Injectable()
export class PwaService {
  private pwaComponentRef: ComponentRef<PwaPromptComponent>;
  private event: Subject<BeforeInstallPromptEvent> = new Subject<BeforeInstallPromptEvent>();
  private _event: BeforeInstallPromptEvent;
  private readonly PwaInstalled: string = 'PwaInstalled';
  private pwaConfig: PwaConfig;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  private SetupPrompt() {
    window.addEventListener('beforeinstallprompt', (beforeInstallPromptEvent: BeforeInstallPromptEvent) => {
      beforeInstallPromptEvent.preventDefault();
      this.event.next(beforeInstallPromptEvent);
    });
  }

  private AppendPromptToBody(config: PwaConfig, componentFactory: any): any {
    const map = new WeakMap();
    map.set(PwaConfig, config);

    const componentRef = componentFactory.create(new GenericInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    document.body.style.marginBottom = '100px';

    return componentRef;
  }

  private CreatePwaPrompt(config: PwaConfig) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PwaPromptComponent);

    this.pwaComponentRef = this.AppendPromptToBody(config, componentFactory);

    if (this.pwaComponentRef?.instance) {
      this.pwaComponentRef.instance.promptClick.subscribe(() => {
        this._event.prompt();
        window.localStorage.removeItem(this.PwaInstalled);

        this._event.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            localStorage.setItem(this.PwaInstalled, this.PwaInstalled);
          } else {
            console.log('User dismissed the install prompt');
          }
        });
      });
    }
  }

  private CreateInstalledComponent(config: PwaConfig) {
    config.isInstalled = true;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PwaInstalledPromptComponent);
    this.AppendPromptToBody(config, componentFactory);
  }

  SetupPwa(config: PwaConfig) {
    this.pwaConfig = config;
    this.SetupPrompt();
    this.event.subscribe((promptEvent) => {
      this._event = promptEvent;
      this.CreatePwaPrompt(this.pwaConfig);
      this.event.unsubscribe();
    });
  }

  async checkIfInstalled() {
    window.addEventListener('DOMContentLoaded', () => {
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        if (localStorage.getItem(this.PwaInstalled)) {
          this.CreateInstalledComponent(this.pwaConfig);
        }
      }
    });
  }

  isBackgroundSyncSupported() {
    return 'serviceWorker' in navigator && 'SyncManager' in window;
  }

  registerServiceWorker() {
    if (this.isBackgroundSyncSupported()) {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration: any) => {
        serviceWorkerRegistration.sync.register('inspection_submitted');
      });

      return true;
    }

    return false;
  }

  unregisterServiceWorker() {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }

  isConnectedToInternet() {
    return navigator.onLine;
  }

  isConnectedViaWifi() {
    const connection = navigator['connection'] || navigator['webkitConnection'] || navigator['mozConnection'];
    const connectionType = connection?.type;
    const effectiveConnectionType = connection?.effectiveType;

    if (connectionType) {
      if (connectionType === ConnectionType.wifi) {
        return true;
      } else {
        return false;
      }
    }

    if (effectiveConnectionType) {
      // 4g seems to be returned for wifi as it may be downlink dependent
      if (
        effectiveConnectionType === ConnectionEffectiveType.slow2G ||
        effectiveConnectionType === ConnectionEffectiveType.twoG ||
        effectiveConnectionType === ConnectionEffectiveType.threeG
      ) {
        return false;
      }
    }

    return null;
  }
}
