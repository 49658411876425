import { Component, EventEmitter, Output } from '@angular/core';
import { DialogConfig } from '../../../dialog/dialog.config';

@Component({
  selector: 'tih-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
  @Output() completeClick$ = new EventEmitter<void>();

  constructor(public dialogConfig: DialogConfig) {}

  completeClick() {
    this.completeClick$.next();
  }
}
