// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-thank-you-view {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.survey-thank-you-view .survey-thank-you-view__icon-tick {
  color: var(--main-4);
  font-size: 70px;
}
.survey-thank-you-view .survey-thank-you-view__text {
  color: var(--sub-6);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
