import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { WindscreenDamage } from '../../model/capture-windscreen-damage.model';

@Component({
  selector: 'tih-cwd-step1',
  templateUrl: './cwd-step1.component.html',
  styleUrls: ['../cwd-shared.scss']
})
export class CwdStep1Component implements AfterViewInit {
  @ViewChild('damageTypeDisplay', { static: true })
  private damageTypeDisplay: ElementRef<HTMLElement>;

  @Input()
  windscreenDamage = new WindscreenDamage();
  @Input()
  currentStep: number;

  async ngAfterViewInit() {
    setTimeout(() => {
      this.scrollOpen(this.windscreenDamage?.hasWindscreenDamage);
    }, 0);
  }

  hasWindscreenDamageChange(event): void {
    this.windscreenDamage.hasWindscreenDamage = event;
    this.scrollOpen(event);
  }

  private scrollOpen(open: boolean): void {
    const elementHeight = open ? this.damageTypeDisplay.nativeElement.scrollHeight + 'px' : '0px';
    this.damageTypeDisplay.nativeElement.style.maxHeight = elementHeight;
  }

  windscreenDamageTypeChange(event): void {
    if (this.windscreenDamage.type?.toLowerCase() !== event.toLowerCase()) {
      this.windscreenDamage.area.dataUri = '';
    }

    this.windscreenDamage.type = event;
  }
}
