// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-view {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 10%;
  z-index: 1000;
  box-shadow: 0 0 23px 0 rgba(153, 153, 153, 0.2);
}
.toast-view .toast-view__text {
  color: var(--sub-6);
}
@media (orientation: landscape) {
  .toast-view {
    right: 2%;
    left: 10%;
    top: 0;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
