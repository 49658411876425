// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capture-windscreen-damage {
  display: flex;
  flex-direction: column;
}
.capture-windscreen-damage .align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.capture-windscreen-damage .radio-container {
  float: left;
  width: 100%;
}
.capture-windscreen-damage .radio-container .radio-input {
  min-width: 33.33%;
  max-width: 33.33%;
  float: left;
}
.capture-windscreen-damage .radio-container .radio-input-image {
  min-width: 50%;
  max-width: 50%;
  float: left;
  box-sizing: border-box;
}
.capture-windscreen-damage .radio-container .radio-input-image label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.capture-windscreen-damage .radio-container .radio-input-image label span {
  padding-left: 15px;
}
.capture-windscreen-damage .text-center {
  text-align: center;
}
.capture-windscreen-damage .radio-button-image {
  border-radius: 100%;
  border: 1px solid transparent;
}
.capture-windscreen-damage .radio-image {
  display: none !important;
}
.capture-windscreen-damage .radio-image:checked + label .radio-button-image {
  border: 1px solid blue;
}
.capture-windscreen-damage .back-next-component {
  display: flex;
  justify-content: space-between;
}
.capture-windscreen-damage .button-component {
  border-radius: 100px;
  padding: 10px 15px;
  text-align: center;
  border: none;
  flex: 0.1;
  width: 100%;
  font-size: 16px;
}
.capture-windscreen-damage .button-component .button-component__icon {
  display: flex;
  justify-content: center;
}
.capture-windscreen-damage .button-component .button-component__icon:before {
  align-items: center;
  display: flex;
  font-size: 18px;
}
.capture-windscreen-damage .button-component .button-component__icon.icon--left {
  flex-direction: row;
}
.capture-windscreen-damage .button-component .button-component__icon.icon--left .button-component__text {
  padding-left: 15px;
}
.capture-windscreen-damage .button-component .button-component__icon.icon--right {
  flex-direction: row-reverse;
}
.capture-windscreen-damage .button-component .button-component__icon.icon--right .button-component__text {
  padding-right: 15px;
}
.capture-windscreen-damage .button-component.m1 {
  background-color: var(--main-1);
  color: var(--sub-1);
}
.capture-windscreen-damage .button-component.m1:disabled, .capture-windscreen-damage .button-component.m1.disabled {
  background-color: transparent;
  color: var(--main-1);
  border: 1px solid;
}
.capture-windscreen-damage .button-component.s5 {
  background-color: var(--sub-5);
  color: var(--sub-1);
}
.capture-windscreen-damage .button-component.s5:disabled, .capture-windscreen-damage .button-component.s5.disabled, .capture-windscreen-damage .button-component.s5.inverse {
  background-color: transparent;
  color: var(--sub-5);
  border: 1px solid;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
