// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.front-color {
  color: #666;
}

.main-heading {
  font-size: 21px;
}

.sub-heading {
  font-size: 11px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.error {
  color: #ed1c24;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
