// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-capture-button {
  display: -webkit-flex;
  -webkit-justify-content: flex-end;
  -webkit-align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: flex-end;
  height: 100%;
  padding: 15px;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex-direction: column;
}
.camera-capture-button .camera-capture-button__outer-circle {
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  min-width: 60px;
  max-width: 85px;
  outline: none;
  color: var(--sub-1);
  border: 3px solid;
  border-radius: 100%;
  overflow: hidden;
  min-height: 60px;
  max-height: 85px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  cursor: pointer;
}
.camera-capture-button .camera-capture-button__outer-circle .outer-circle__resizer {
  margin-top: 100%;
}
.camera-capture-button .camera-capture-button__outer-circle .outer-circle__inner-circle {
  background-color: var(--sub-1);
  width: 90%;
  height: 90%;
  border-radius: inherit;
  position: absolute;
  transition: 0.5s background-color ease-in-out;
}
.camera-capture-button .camera-capture-button__outer-circle .outer-circle__inner-circle.inner-circle--capturing {
  background-color: var(--main-1);
}
@media (orientation: portrait) {
  .camera-capture-button .camera-capture-button__outer-circle {
    bottom: 15px;
    position: fixed;
  }
}
@media (orientation: landscape) {
  .camera-capture-button {
    flex-direction: row;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
