import { Component, Output, EventEmitter } from '@angular/core';
import { SurveyQuestion, SurveyQuestionResponse } from '../../database-context/tables/vehicle-inspection.model';
import { UserService } from '../../../../../app/services/user.service';

@Component({
  selector: 'tih-capture-survey-response',
  templateUrl: './capture-survey-response.component.html',
  styleUrls: ['./capture-survey-response.component.scss']
})
export class CaptureSurveyResponseComponent {
  @Output() confirmClick$ = new EventEmitter<Array<SurveyQuestionResponse>>();

  survey: Array<SurveyQuestionResponse>;
  surveyQuestions: Array<SurveyQuestion>;
  currentStep = 1;

  constructor(public userService: UserService) {
    this.survey = [];
    this.surveyQuestions = null;
  }

  setSurveyQuestions(surveyQuestions: Array<SurveyQuestion>) {
    this.surveyQuestions = surveyQuestions;
    this.initializeSurveyArray(surveyQuestions);
  }

  initializeSurveyArray(surveyQuestions: Array<SurveyQuestion>) {
    //Forcing survey list to be empty for initialization
    this.survey = [];
    for (let i = 0; i < surveyQuestions?.length; i++) {
      this.survey.push({ description: '', rating: null, id: surveyQuestions[i].id });
    }
  }

  confirmClick() {
    this.confirmClick$.emit(this.survey);
    this.currentStep++;
  }
}
