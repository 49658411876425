import { Component, OnInit } from '@angular/core';
import { animate, AnimationEvent, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { ToastConfig } from '../config/toast.config';

@Component({
  selector: 'tih-toast',
  templateUrl: './toast-view.component.html',
  styleUrls: ['./toast-view.component.scss'],
  animations: [
    trigger('fadeOut', [transition('* => void', [style({ opacity: 1 }), animate(500, style({ opacity: 0 }))])])
  ]
})
export class ToastViewComponent implements OnInit {
  readonly DEFAULT_TIME_VISIBLE = 5;

  icon: string;
  iconColour: string;
  assetPath: string;
  toastMessage: string;
  timeVisibleInSeconds: number;
  isVisible = true;
  removeToastSubj: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private config: ToastConfig) {}

  async ngOnInit() {
    this.icon = this.config.icon;
    this.iconColour = this.config.iconColour;
    this.assetPath = this.config.assetPath;
    this.toastMessage = this.config.toastMessage;
    this.timeVisibleInSeconds = this.config.timeVisibleInSeconds;

    this.startHideToastTimer();
  }

  startHideToastTimer(): void {
    let remainingTime = this.timeVisibleInSeconds != null ? this.timeVisibleInSeconds : this.DEFAULT_TIME_VISIBLE;

    setInterval(() => {
      if (remainingTime > 0) {
        remainingTime--;
      } else {
        this.isVisible = false;
      }
    }, 1000);
  }

  removeToast(event: AnimationEvent): void {
    if (event.toState === 'void') {
      this.removeToastSubj.next(true);
    }
  }
}
