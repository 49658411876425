class CameraInstance {
  dataUri: string;
  name: string;
}

class InspectionPhoto {
  images: Array<CameraInstance> = new Array<CameraInstance>();
  location: string;
  label: string;
  icon: string;
}

class BarcodeResults extends CameraInstance {
  scanResult: string;

  constructor() {
    super();
  }
}

class WindscreenDamage {
  type: string;
  subType: string;
  area: CameraInstance = new CameraInstance();
  photo: CameraInstance = new CameraInstance();
}

class Accessories {
  addons: boolean;
  description: string;
}

export class Vehicle {
  description?: string;
  year?: number;
  model?: string;
  make?: string;
  color?: string;
  colorCode?: string;
  mmCode?: string;
  vinNumber?: string;
  registrationNumber?: string;
  sequenceNumber?: string;
  premium?: string;
  licenseDisk?: BarcodeResults = new BarcodeResults();
  odometer?: number;
  photos?: Array<InspectionPhoto> = [];
  damages?: Array<InspectionPhoto> = [];
  additionalDamageComments = '';
  hasDamages?: boolean;
  hasWindscreenDamages?: boolean;
  windscreenDamage?: Array<WindscreenDamage> = [];
  accessories?: Accessories = new Accessories();
}

export class Driver {
  licenseCard?: Array<BarcodeResults> = [];
  name?: string;
  surname?: string;
  idNumber?: string;
  dateOfBirth?: string;
  licenseIssueDate?: string;
}

class Base {
  public brand: string;
  public policyNumber: string;
  public lastModified: Date;

  get getKey() {
    return `${this.brand}/${this.policyNumber}`;
  }
}

export class VehicleInspection extends Base {
  key?: string;
  idNumber?: string;
  vehicleNumber?: number;
  type?: string;
  vehicle?: Vehicle = new Vehicle();
  driver?: Driver = new Driver();
  setKey?: (key: string) => string[];
  step?: number;
  status?: string;
  uploadSuccess?: boolean;
  uploadMessage?: string;
  uploadProgress?: number;
  isUploading?: boolean;
  uploadSize?: any;
  inspectionId?: number;

  override get getKey() {
    this.key = `${this.idNumber}/${this.brand}/${this.policyNumber}/${this.vehicleNumber}`;
    return this.key;
  }

  constructor() {
    super();
  }
}

// FIXME: (Kane Mooi) Combine SurveyQuestion & SurveyQuestionResponse
export class SurveyQuestion {
  id: number;
  description: string;
}

export class SurveyQuestionResponse {
  id: number;
  description: string;
  rating: number;
}

export class CallResponse<T> {
  payload: T;
  success: boolean;
}

VehicleInspection.prototype.setKey = function (key?: string) {
  const keyValue = this.key || key;

  try {
    const splitValue = keyValue.split('/');
    this.idNumber = splitValue[0];
    this.brand = splitValue[1];
    this.policyNumber = splitValue[2];
    this.vehicleNumber = splitValue[3];
    this.key = keyValue;
    return splitValue;
  } catch (exception) {
    console.error(exception);
    return [];
  }
};
