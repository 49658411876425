import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tih-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {
  @Input() id = 'btnItem';
  @Input() iconDirection = '';
  @Input() icon = '';
  @Input() theme = '';
  @Input() disabled = false;
  @Input() buttonType = 'button';

  @Output() click$ = new EventEmitter<void>();

  buttonClick(): void {
    this.click$.emit();
  }
}
