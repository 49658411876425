import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Step1ViewComponent } from './step1/step1-view.component';
import { Step2ViewComponent } from './step2/step2-view.component';
import { Step3ViewComponent } from './step3/step3-view.component';
import { StepsViewComponent } from './steps/steps-view.component';
import { VehiclesComponent } from './vehicles/vehicles.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/vehicles',
    pathMatch: 'full'
  },
  {
    path: 'vehicles',
    component: VehiclesComponent,
    data: { animationId: '0' }
  },
  {
    path: 'inspection/:policy/:id',
    component: StepsViewComponent,
    children: [
      {
        path: '',
        redirectTo: 'step-1',
        pathMatch: 'full'
      },
      {
        path: 'step-1',
        component: Step1ViewComponent,
        data: { animationId: '0' }
      },
      {
        path: 'step-2',
        component: Step2ViewComponent,
        data: { animationId: '1' }
      },
      {
        path: 'step-3',
        component: Step3ViewComponent,
        data: { animationId: '2' }
      }
    ],
    data: { animationId: '1' }
  },
  {
    path: '**',
    redirectTo: '/vehicles',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
