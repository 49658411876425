// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-component {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.camera-component .camera-component__controls {
  flex: 0.1;
  background-color: white;
  height: 100%;
  max-height: 80px;
}
.camera-component .camera-component__video {
  position: relative;
  flex: auto;
}
@media (orientation: landscape) {
  .camera-component {
    flex-direction: row;
  }
  .camera-component .camera-component__controls {
    max-height: 100%;
    max-width: 80px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
