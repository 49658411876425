import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component/dialog.component';
import { DisplayComponentDirective } from './directives/display-component.directive';
import { DialogService } from './services/dialog.service';

@NgModule({
  imports: [CommonModule],
  declarations: [DialogComponent, DisplayComponentDirective],
  providers: [DialogService]
})
export class DialogModule {}
