import { Component, EventEmitter, Output } from '@angular/core';
import { WindscreenDamage } from './model/capture-windscreen-damage.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'tih-capture-windscreen-damage',
  templateUrl: './capture-windscreen-damage.component.html',
  styleUrls: ['./capture-windscreen-damage.component.scss']
})
export class CaptureWindscreenDamageComponent {
  @Output() completeClick$ = new EventEmitter();
  @Output() backClick$ = new EventEmitter<void>();

  windscreenDamage = new WindscreenDamage();
  windscreenDamage$ = new Subject<WindscreenDamage>();

  currentStep = 1;

  constructor() {
    this.windscreenDamage$.subscribe((windscreen: WindscreenDamage) => {
      this.setWindscreenDamage(windscreen);
    });
  }

  setWindscreenDamage(windscreenDamage: WindscreenDamage): void {
    this.windscreenDamage.photo = windscreenDamage?.photo ?? this.windscreenDamage.photo;
    this.windscreenDamage.area = windscreenDamage?.area ?? this.windscreenDamage.area;
    this.windscreenDamage.type = windscreenDamage?.type ?? this.windscreenDamage.type;
    this.windscreenDamage.subType = windscreenDamage?.subType ?? this.windscreenDamage.subType;
    this.windscreenDamage.hasWindscreenDamage =
      windscreenDamage?.hasWindscreenDamage ?? this.windscreenDamage.hasWindscreenDamage;
    this.windscreenDamage.hasAdditionalWindscreenDamage =
      windscreenDamage?.hasAdditionalWindscreenDamage ?? this.windscreenDamage.hasAdditionalWindscreenDamage;
  }

  next(): void {
    if (this.windscreenDamage?.hasWindscreenDamage) {
      this.currentStep += 1;
    } else {
      this.completeClick$.emit({ index: null, newItem: false });
    }
  }

  back(): void {
    this.currentStep -= 1;
  }

  backClick(): void {
    this.backClick$.emit();
  }

  completeClick(newItem?: boolean): void {
    this.completeClick$.emit({
      index: null,
      newItem: newItem
    });
  }
}
