import { Component } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, Router } from '@angular/router';
import { VehicleInspectionService } from '../services/vehicle-inspection.service';
import { RouterAnimations } from 'src/modules/@tih/budget/animations/router.animation';
import { StepValidationService } from '../services/step-validation.service';
import { GtmDataLayerService } from '../../modules/@tih/gtm-datalayer/gtm-data-layer.service';
import { Events } from '../../modules/@tih/gtm-datalayer/enums/events.enum';
import { StepNames } from '../../modules/@tih/gtm-datalayer/enums/step-names.enum';
import { Names } from '../../modules/@tih/gtm-datalayer/enums/names.enum';

@Component({
  selector: 'tih-steps-view',
  templateUrl: './steps-view.component.html',
  styleUrls: ['./steps-view.component.scss'],
  animations: [RouterAnimations.routeAnimation]
})
export class StepsViewComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stepValidationService: StepValidationService,
    private dataLayerService: GtmDataLayerService,
    public inspectionService: VehicleInspectionService,
    private contexts: ChildrenOutletContexts
  ) {
    const { policy, id } = this.route.snapshot.params;
    const activeUserId = localStorage.getItem('activeUserId');
    this.inspectionService.setActiveInspection(`${activeUserId}/Budget/${policy}/${id}`);
    this.dataLayerService.dataLayerInit(Events.FormStart, policy, Names.Inspections, StepNames.StartSelfInspection);
    this.dataLayerService.pushToDataLayer();
  }

  isValid(step: number) {
    return this.stepValidationService.isStepValid(step, this.inspectionService.activeInspection);
  }

  isCurrentRoute(step: number): boolean {
    const path = `step-${step}`;

    if (this.router.url.includes(path)) {
      return true;
    }

    return false;
  }

  // (Kane Mooi) This does not work when it is in a service and is also used in the app.component
  // This method returns the animation ID for the current route, if it exists.
  getRouteAnimationData(): string | undefined {
    try {
      return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animationId'] || '0';
    } catch (error: unknown) {
      console.error('Error in getRouteAnimationData:', error);
      return null;
    }
  }
}
