import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { DialogConfig } from '../dialog.config';
import { DisplayComponentDirective } from '../directives/display-component.directive';

@Component({
  selector: 'tih-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, AfterViewInit {
  @Output() closeDialog = new EventEmitter<true>();

  @ViewChild(DisplayComponentDirective)
  displayComponent: DisplayComponentDirective;

  displayComponentRef: Subject<ComponentRef<any>> = new Subject<ComponentRef<any>>();
  displayComponentType: Type<any>;

  constructor(private changeDetector: ChangeDetectorRef, public config: DialogConfig) {}

  // FIXME: (Kane Mooi) This is called weirdly in the service
  /* eslint-disable */
  async ngOnInit() {}
  /* eslint-enable */

  ngAfterViewInit(): void {
    if (this.displayComponentType) {
      this.loadDisplayComponent(this.displayComponentType);
      this.changeDetector.detectChanges();
    }
  }

  loadDisplayComponent(componentType: Type<any>): void {
    const viewContainerRef = this.displayComponent.viewContainerRef;
    viewContainerRef.clear();
    const view = viewContainerRef.createComponent(componentType);
    view.changeDetectorRef.detectChanges();
    this.displayComponentRef.next(view);
  }

  close(): void {
    this.closeDialog.emit(true);
  }
}
