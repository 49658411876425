// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 50px;
}

.pwa-prompt {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.pwa-prompt .container {
  width: 100%;
}
.pwa-prompt .pwa-card {
  box-shadow: -2px 3px 20px -10px grey;
  float: left;
  padding: 5px;
  border-radius: 15px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  text-align: center;
}
.pwa-prompt .logo {
  float: left;
  padding: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
