export class Utilities {
  public static deepCopy(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  public static roundToPrecision(value, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public static bytesToMegaBytes(bytes: number): number {
    return this.roundToPrecision(bytes / 1e6);
  }

  public static sizeInBytes(object: any) {
    const objectAsString = JSON.stringify(object);
    const { size } = new Blob([objectAsString]);
    const megaBytes = this.bytesToMegaBytes(size);
    return { bytes: size, megaBytes: megaBytes };
  }

  public static titleCase(str: string): string {
    return str
      .toLowerCase()
      .split(' ')
      .map((x: string) => x[0].toUpperCase() + x.slice(1))
      .join('');
  }
}
