// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-container {
  float: left;
  width: 100%;
}
.radio-container .radio-input {
  min-width: 33.33%;
  max-width: 33.33%;
  float: left;
}
.radio-container .radio-input-image {
  min-width: 50%;
  max-width: 50%;
  float: left;
  display: flex;
  align-items: center;
}
.radio-container .radio-input-image span {
  font-size: 12px;
  color: #919191;
}
.radio-container .radio-input-image span:not(.radio-icon) {
  padding-left: 10px;
}
.radio-container .radio-input-image span.radio-icon {
  font-size: 30px;
  color: var(--main-1);
}
.radio-container .checkbox-input {
  min-width: 50%;
  max-width: 50%;
  float: left;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.radio-container input[type=checkbox] {
  -webkit-appearance: radio; /* Chrome, Safari, Opera */
  -moz-appearance: radio; /* Firefox */
}
.radio-container .radio-button-image {
  border-radius: 100%;
  border: 3px solid transparent;
}
.radio-container .radio-image {
  display: none !important;
}
.radio-container .radio-image:checked + label .radio-button-image {
  border-color: var(--main-1);
}
.radio-container [type=radio],
.radio-container [type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.radio-container [type=radio] + img {
  cursor: pointer;
}
.radio-container [type=radio]:checked + img {
  border: 3px solid var(--main-1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
