import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CaptureAdditionalDamage } from './model/capture-additional-damage.model';

@Component({
  selector: 'tih-capture-additional-damage',
  templateUrl: './capture-additional-damage.component.html',
  styleUrls: ['./capture-additional-damage.component.scss']
})
export class CaptureAdditionalDamageComponent {
  @Output() confirmClick$ = new EventEmitter();
  @Output() backClick$ = new EventEmitter<void>();

  additionalDamage = new CaptureAdditionalDamage();
  additionalDamage$ = new Subject<any>();

  constructor() {
    this.additionalDamage$.subscribe((damages) => {
      this.setAdditionalDamages(damages);
    });
  }

  setAdditionalDamages(additionalDamages: any): void {
    this.additionalDamage.hasAdditionalDamage = additionalDamages.hasDamages;
    this.additionalDamage.additionalDamageTypes = additionalDamages?.photos;
    this.additionalDamage.damageAreas
      .filter((area) => {
        return additionalDamages?.photos?.some((photo) => photo.id === area.id);
      })
      .forEach((damageArea) => {
        damageArea.checked = true;
      });
  }

  hasAdditionalDamageChange(event): void {
    this.additionalDamage.hasAdditionalDamage = event;
  }

  damageTypeChanged(selectedDamages): void {
    const concatArray = [];
    const damageTypeMap = this.additionalDamage.additionalDamageTypes.map((damage: any) => damage.id);
    const selectedDamageMap = selectedDamages.map((damage, index) => {
      return { id: damage.id, index: index };
    });

    selectedDamageMap.forEach((damage: any) => {
      const index = damageTypeMap.indexOf(damage.id);
      if (index < 0) {
        concatArray.push(selectedDamages[damage.index]);
      } else {
        const slicedValue = this.additionalDamage.additionalDamageTypes[index];
        concatArray.push(slicedValue);
      }
    });

    this.additionalDamage.additionalDamageTypes = concatArray;
  }

  backClick(): void {
    this.backClick$.emit();
  }

  confirmClick(): void {
    this.confirmClick$.emit(this.additionalDamage);
  }
}
