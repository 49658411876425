import { Component, OnInit, Renderer2 } from '@angular/core';
import { PwaService } from '../modules/@tih/pwa/pwa.service';
import { RouterAnimations } from 'src/modules/@tih/budget/animations/router.animation';
import { ToastService } from 'src/modules/@tih/toast/services/toast.service';
import { ToastConfig } from 'src/modules/@tih/toast/config/toast.config';
import { UserService } from './services/user.service';
import { GtmDataLayerService } from '../modules/@tih/gtm-datalayer/gtm-data-layer.service';
import { ConfigurationService } from '../modules/@tih/configuration/configuration.service';
import { InfoDialogComponent } from '../modules/@tih/budget/components/info-dialog/info-dialog.component';
import { DialogService } from '../modules/@tih/dialog/services/dialog.service';
import { ChildrenOutletContexts } from '@angular/router';

@Component({
  selector: 'tih-root',
  templateUrl: './app.component.html',
  animations: [RouterAnimations.routeAnimation]
})
export class AppComponent implements OnInit {
  loading = false;

  constructor(
    private pwaService: PwaService,
    private contexts: ChildrenOutletContexts,
    private toastService: ToastService,
    private userService: UserService,
    private gtmDataLayerService: GtmDataLayerService,
    private configuration: ConfigurationService,
    private renderer: Renderer2,
    private dialogService: DialogService
  ) {
    this.gtmDataLayerService.addGtm(this.renderer, this.configuration.gtmIds.gtmId360);
  }

  async ngOnInit() {
    const showFirstTimeDialog = localStorage.getItem('firstTime');
    if (showFirstTimeDialog !== 'no') {
      setTimeout(() => {
        this.openFirstTimeDialog();
      }, 0);
    }

    this.userService.setActiveUser();
    this.pwaService.SetupPwa({ brand: 'budget' });

    await this.pwaService.checkIfInstalled();

    if (!this.pwaService.isConnectedToInternet()) {
      let offlineMessage = '';
      if (this.pwaService.isBackgroundSyncSupported()) {
        offlineMessage =
          "You appear to be offline but you can still complete your inspection. We'll save your progress and submit it once your device is connected to the internet again";
      } else {
        offlineMessage =
          "You appear to be offline, your inspection progress will be saved and you can submit it when you're back online";
      }

      const toastConfig = new ToastConfig(offlineMessage, 6);
      toastConfig.icon = 'icon-error';
      toastConfig.iconColour = 'main-5';
      this.toastService.displayToast(toastConfig);
    } else if (this.pwaService.isConnectedViaWifi() === false) {
      const toastConfig = new ToastConfig(
        'You seem to be using mobile data, please be aware that submitting your inspection may incur additional data costs',
        6
      );

      toastConfig.icon = 'icon-icon-question-mark';
      toastConfig.iconColour = 'main-5';
      this.toastService.displayToast(toastConfig);
    }
  }

  openFirstTimeDialog(): void {
    const dialogConfig = {
      data: {
        icon: '../../../assets/images/welcome/welcome.svg',
        heading: 'Welcome to your self inspection process',
        text: 'Complete your self inspection online now to ensure you are fully covered.',
        iconType: 'asset'
      },
      displayType: InfoDialogComponent
    };

    this.dialogService.open(dialogConfig).then((service) => {
      service.view.subscribe((component) => {
        component.instance.completeClick$.subscribe(() => {
          localStorage.setItem('firstTime', 'no');
          this.dialogService.close();
        });

        service.dialog.instance.closeDialog.subscribe(() => {
          localStorage.setItem('firstTime', 'no');
        });
      });
    });
  }

  toggleLoader(event: boolean): void {
    this.loading = event;
  }

  // (Kane Mooi) This does not work when it is in a service and is also used in the steps-view.component
  // This method returns the animation ID for the current route, if it exists.
  getRouteAnimationData(): string | undefined {
    try {
      return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animationId'] || '0';
    } catch (error: unknown) {
      console.error('Error in getRouteAnimationData:', error);
      return null;
    }
  }
}
