import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleInspectionService } from '../services/vehicle-inspection.service';
import { PwaService } from '../../modules/@tih/pwa/pwa.service';
import { ConfigurationService } from '../../modules/@tih/configuration/configuration.service';

@Component({
  selector: 'tih-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent {
  loading = false;
  last: boolean;

  constructor(
    private router: Router,
    public inspectionService: VehicleInspectionService,
    public pwaService: PwaService,
    private globalConfig: ConfigurationService
  ) {
    if (!this.inspectionService?.inspectionList || this.inspectionService?.inspectionList?.length <= 0) {
      this.loading = true;
      this.inspectionService.setInspectionList().then(() => {
        this.loading = false;
      });
    }
  }

  selectedVehicle(inspection): void {
    const stepToPath = {
      1: '/step-1',
      2: '/step-2',
      3: '/step-3'
    };

    const routePath = `/inspection/${inspection.policyNumber}/${inspection.vehicleNumber}${
      stepToPath[inspection.step] || ''
    }`;

    this.router.navigate([routePath]).catch((error) => console.error(error));
  }

  submitVehicle(index: number): void {
    if (!this.pwaService.isConnectedToInternet()) {
      this.pwaService.registerServiceWorker();
    }

    this.inspectionService.submitInspection(index);
  }

  checkLast(last: boolean): void {
    this.last = last;
    this.loading = !this.last;
  }

  goToDashboard(): void {
    this.loading = true;
    window.location.href = `${this.globalConfig.baseBudgetUrl}/dashboard`;
  }
}
