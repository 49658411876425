import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  baseUrl: string;

  constructor(private httpClient: HttpClient, configuration: ConfigurationService) {
    this.baseUrl = configuration.baseBudgetUrl;
  }

  public getSelf<T>(servicePoint: string): Observable<T> {
    return this.httpClient.get<T>(`/${servicePoint}`).pipe(
      catchError((error: unknown) => {
        console.error(error);
        return throwError(error);
      })
    );
  }

  public postSelf<T>(servicePoint: string, object: any): Observable<any> {
    const headers = new HttpHeaders({ 'ngsw-bypass': '' });

    return this.httpClient
      .post<T>(`/${servicePoint}`, object, {
        headers,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return throwError(error);
        }),
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress: {
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', progress: progress, loaded: event.loaded };
            }
            case HttpEventType.Response:
              return event.body;
            default:
              return { status: 'unhandled' };
          }
        })
      );
  }
}
