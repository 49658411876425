import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PwaService } from '../modules/@tih/pwa/pwa.service';
import { CameraModule } from '../modules/@tih/camera/camera.module';
import { VehicleInspectionService } from './services/vehicle-inspection.service';
import { DatabaseContext } from '../modules/@tih/budget/database-context/database.context';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'src/modules/@tih/toast/toast.module';

import { DialogModule } from 'src/modules/@tih/dialog/dialog.module';
import { BudgetModule } from '../modules/@tih/budget/budget.module';
import { ConfigurationService } from '../modules/@tih/configuration/configuration.service';
import { HttpClientService } from '../modules/@tih/httpclient/http-client.service';
import { StepsViewComponent } from './steps/steps-view.component';
import { Step1ViewComponent } from './step1/step1-view.component';
import { Step2ViewComponent } from './step2/step2-view.component';
import { Step3ViewComponent } from './step3/step3-view.component';
import { CommonModule } from '@angular/common';
import { UserService } from './services/user.service';
import { GtmDataLayerService } from '../modules/@tih/gtm-datalayer/gtm-data-layer.service';
import { CustomInterceptor } from './interceptors/custom.interceptor';
import { AppRoutingModule } from './app-routing.module';

const appInitializerFactory = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    VehiclesComponent,
    StepsViewComponent,
    Step1ViewComponent,
    Step2ViewComponent,
    Step3ViewComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CameraModule,
    ToastModule,
    DialogModule,
    BudgetModule,
    CommonModule,
    ServiceWorkerModule.register(environment.serviceWorkerScript)
  ],
  providers: [
    PwaService,
    VehicleInspectionService,
    UserService,
    DatabaseContext,
    ConfigurationService,
    GtmDataLayerService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [ConfigurationService]
    },
    HttpClientService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
