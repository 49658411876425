import { Events } from '../enums/events.enum';
import { Names } from '../enums/names.enum';
import { StepNames } from '../enums/step-names.enum';
import { YesNo } from '../enums/yes-no.enum';

export class Message {
  'errors': YesNo;
  'type': 'success' | 'unsuccessful';
}

export class AddCover {
  'cellphoneIncluded'?: YesNo;
  'bicycleIncluded'?: YesNo;
  'glassesIncluded'?: YesNo;
  'laptopselectronicsIncluded'?: YesNo;
  'jewelleryIncluded'?: YesNo;
  'videophotographicIncluded'?: YesNo;
  'soundequipmentIncluded'?: YesNo;
  'musicalequipmentIncluded'?: YesNo;
  'firearmsIncluded'?: YesNo;
  'otherIncluded'?: YesNo;
}

class History {
  'newValue': any;
  'oldValue': any;
}

class DataLayerForm {
  'name': Names;
  'type': string;
  'stepname': StepNames;
  'addcover'?: AddCover;
}

export class DataLayer {
  'event': Events;
  'policynumber': string;
  'form' = new DataLayerForm();
  'history'?: History;
  'messages'?: Message;
}
