export class UserDetails {
  policyHolderIdNumber: string;
  profilePicture?: string;
  fieldNotifications?: FieldNotifications;
  dashboardMessages?: DashboardDisplayMessage;
  policyHolderFirstName?: string;
  policyHolderLastName?: string;
  policyHolderDateOfBirth?: string;
  initials?: string;
}

class FieldNotifications {
  vehicle: Array<DashboardFieldNotificationItem>;
  building: Array<DashboardFieldNotificationItem>;
  home: Array<DashboardFieldNotificationItem>;
  portable: Array<DashboardFieldNotificationItem>;
  person: Array<DashboardFieldNotificationItem>;
}

class DashboardFieldNotificationItem {
  displayName: string;
  type: string;
  sequenceNumber: string;
  missingFields: Array<OutstandingField>;
}

class OutstandingField {
  displayProperty: string;
  property: string;
  propertySubType: string;
  notificationType: string;
  isUpload: boolean;
  isPopUp: boolean;
}

class DashboardDisplayMessage {
  messageId: number;
  senderFirstName: string;
  senderLastName: string;
  recipientFirstName: string;
  recipientLastName: string;
  messageTitle: string;
  messageSummary: string;
  messageBody: string;
  dateReceivedDisplay: string;
  dateReceivedTicks: string;
  attachments: Array<DashboardDisplayAttachment>;
  isRead: boolean;
  amendStatus: string;
}

class DashboardDisplayAttachment {
  url: string;
  attachmentType: DashboardMessageAttachmentType;
}

enum DashboardMessageAttachmentType {
  Survey,
  File,
  Image,
  Invalid
}
