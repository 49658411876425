import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CameraService } from '../../../../../camera/services/camera.service';
import { WindscreenDamage } from '../../model/capture-windscreen-damage.model';
import { CameraOverlayComponent } from '../../../camera-overlay.component/camera-overlay.component';
import { Utilities } from '../../../../../helpers/utilities';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'tih-cwd-step2',
  templateUrl: './cwd-step2.component.html',
  styleUrls: ['../cwd-shared.scss']
})
export class CwdStep2Component {
  @Input()
  windscreenDamage: WindscreenDamage;

  @Input()
  set currentStep(step: number) {
    this.currentStep$.next(step);
  }

  @Output() nextStep = new EventEmitter<boolean>();
  @Output() previousStep = new EventEmitter<boolean>();

  windscreenImage: string = null;
  windscreenConfirmed = false;

  currentStep$ = new Subject<number>();

  constructor(private cameraService: CameraService) {
    this.currentStep$.subscribe((step) => {
      if (!this.windscreenDamage?.photo?.dataUri && step === 2) {
        setTimeout(() => {
          this.openCamera();
        }, 0);
      }
    });
  }

  openCamera(): void {
    const cameraConfig = {
      overlayType: CameraOverlayComponent,
      data: {
        label: 'Wind-screen'
      }
    };

    const windscreenPhoto = [
      {
        location: 'Windscreen',
        label: 'Windscreen',
        icon: 'icon-windscreen',
        suggestionImage: '../../../assets/images/suggestions/Windscreen.jpg'
      }
    ];

    this.cameraService.open(cameraConfig).then((service) => {
      service.overlay.pipe(take(1)).subscribe((component) => {
        component?.instance.doneClick$.subscribe(() => {
          service.camera.destroy();
          component.destroy();
        });

        component?.instance.itemList$.next(Utilities.deepCopy(windscreenPhoto));

        service.camera.instance.cameraResult.subscribe((value) => {
          this.windscreenDamage.photo.dataUri = value?.dataUri;
          component?.instance.setActiveImage({ dataUri: value?.dataUri });
        });
      });
    });

    this.cameraService.onAfterClose.subscribe(() => {
      if (!this.windscreenDamage?.photo?.dataUri) {
        this.previous();
      }
    });
  }

  chipTypeChange(event): void {
    this.windscreenDamage.subType = event;
  }

  chipTypeChanged(value): void {
    this.windscreenDamage.subType = value;
  }

  proceed(): void {
    if (!this.windscreenConfirmed && this.windscreenDamage.type.toLowerCase().indexOf('chipped') > -1) {
      this.windscreenConfirmed = true;
    } else {
      this.nextStep.emit(true);
    }
  }

  previous(): void {
    this.previousStep.emit(true);
  }

  retakePhoto(): void {
    this.openCamera();
  }
}
