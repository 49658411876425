import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tih-camera-capture-button',
  templateUrl: './camera-capture-button.component.html',
  styleUrls: ['./camera-capture-button.component.scss']
})
export class CameraCaptureButtonComponent {
  @Output() photoCapture = new EventEmitter();

  hasVideoTrack = false;

  @Input()
  set setHasVideotrack(value) {
    value.pipe(take(1)).subscribe((track) => {
      if (track) {
        this.hasVideoTrack = true;
      }
    });
  }

  isTouched = false;
  photoTaken = false;

  onTouchStart() {
    this.photoTaken = false;
    this.isTouched = true;
  }

  onTouchEnd(event) {
    this.isTouched = false;
    if (this.isSafari() && event.type === 'touchend') {
      setTimeout(() => {
        if (!this.photoTaken) {
          this.onPhotoCapture();
        }
      }, 100);
    }
  }

  onPhotoCapture() {
    this.photoTaken = true;
    this.photoCapture.emit();
  }

  isSafari() {
    return (
      navigator.userAgent.toLowerCase().indexOf('ipad') > -1 ||
      navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
      navigator.userAgent.toLowerCase().indexOf('ipod') > -1
    );
  }
}
