// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capture-additional-damage {
  display: flex;
  flex-direction: column;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-container {
  float: left;
  width: 100%;
}
.radio-container .radio-input {
  min-width: 33.33%;
  max-width: 33.33%;
  float: left;
}
.radio-container .radio-input-image {
  min-width: 50%;
  max-width: 50%;
  float: left;
  box-sizing: border-box;
}
.radio-container .radio-input-image label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio-container .radio-input-image label span {
  padding-left: 15px;
}

.text-center {
  text-align: center;
}

.radio-button-image {
  border-radius: 100%;
  border: 1px solid transparent;
}

.radio-image {
  display: none !important;
}

.radio-image:checked + label .radio-button-image {
  border: 1px solid blue;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
