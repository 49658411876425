import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { OverlayComponentDirective } from '../../directives/overlay-component.directive';
import { CameraConfig } from '../../camera.config';

@Component({
  selector: 'tih-camera',
  templateUrl: './camera-view.component.html',
  styleUrls: ['./camera-view.component.scss']
})
export class CameraViewComponent implements OnInit, OnDestroy, AfterViewInit {
  // configurations
  isBarcodeScanner = false;
  trackCapabilities: MediaTrackCapabilities;

  // behaviors
  @Output() viewError = new EventEmitter();
  cameraResult: Subject<{ dataUri: string }> = new Subject<{ dataUri: string }>();
  videoTrack: BehaviorSubject<MediaStreamTrack> = new BehaviorSubject(null);
  closeCamera: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // overlay component
  @ViewChild(OverlayComponentDirective)
  overlayComponent: OverlayComponentDirective;
  public overlayComponentRef: Subject<ComponentRef<any>> = new Subject<ComponentRef<any>>();
  public overlayComponentType: Type<any>;

  constructor(private changeDetector: ChangeDetectorRef, public config: CameraConfig) {}

  async ngOnInit() {
    this.videoTrack.subscribe((track: MediaStreamTrack) => {
      if (track) {
        try {
          this.trackCapabilities = track.getCapabilities();
        } catch (error: unknown) {
          console.error(error);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.overlayComponentRef) {
      this.overlayComponentRef.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.overlayComponentType) {
      this.loadOverlayComponent(this.overlayComponentType);
      this.changeDetector.detectChanges();
    }
  }

  loadOverlayComponent(componentType: Type<any>): void {
    const viewContainerRef = this.overlayComponent.viewContainerRef;
    viewContainerRef.clear();
    this.overlayComponentRef.next(viewContainerRef.createComponent(componentType));
  }

  videoTrackLoad(event): void {
    this.videoTrack.next(event);
  }

  close(): void {
    this.closeCamera.next(true);
  }

  onCameraResult(event: { dataUri: string }): void {
    this.cameraResult.next(event);
  }
}
