import { Component } from '@angular/core';
import { VehicleInspectionService } from '../services/vehicle-inspection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../modules/@tih/dialog/services/dialog.service';
import { take } from 'rxjs/operators';
import { AdditionalAccessoriesComponent } from '../../modules/@tih/budget/components/additional-accessories/additional-accessories.component';
import { PwaService } from 'src/modules/@tih/pwa/pwa.service';
import { StepValidationService } from '../services/step-validation.service';
import { UserService } from '../services/user.service';
import { Utilities } from '../../modules/@tih/helpers/utilities';
import { GtmDataLayerService } from '../../modules/@tih/gtm-datalayer/gtm-data-layer.service';
import { Events } from '../../modules/@tih/gtm-datalayer/enums/events.enum';
import { StepNames } from '../../modules/@tih/gtm-datalayer/enums/step-names.enum';
import { Names } from '../../modules/@tih/gtm-datalayer/enums/names.enum';

@Component({
  selector: 'tih-step3-view',
  templateUrl: './step3-view.component.html',
  styleUrls: ['./step3-view.component.scss']
})
export class Step3ViewComponent {
  tryAgain = false;
  data: any;
  showLoader = false;

  constructor(
    public inspectionService: VehicleInspectionService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private pwaService: PwaService,
    private stepValidationService: StepValidationService,
    private dataLayerService: GtmDataLayerService
  ) {}

  openDialog() {
    const dialogConfig = {
      data: {
        icon: 'icon-accessories',
        title: 'Additional add-ons'
      },
      displayType: AdditionalAccessoriesComponent
    };

    this.dialogService.open(dialogConfig).then((service) => {
      service.view.pipe(take(1)).subscribe((component) => {
        component.instance.backClick$.subscribe(() => {
          this.dialogService.close();
        });

        component.instance.additionalAccessories$.next(this.inspectionService.activeInspection.vehicle.accessories);
        component.instance.confirmClick$.subscribe((additionalAccessories) => {
          this.dialogService.close();
          this.inspectionService.activeInspection.vehicle.accessories.addons = additionalAccessories.addons;
          this.inspectionService.activeInspection.vehicle.accessories.description = additionalAccessories.description;
          this.saveStepProgress();
        });
      });
    });
  }

  saveStepProgress() {
    this.inspectionService.saveActiveInspection();
  }

  isValid() {
    return this.stepValidationService.isStepValid(3, this.inspectionService.activeInspection);
  }

  stepBack() {
    this.router.navigate(['./step-2'], { relativeTo: this.route.parent });
  }

  stepNext() {
    this.inspectionService.activeInspection.status = 'Upload Required';
    this.inspectionService.activeInspection.uploadSize = Utilities.sizeInBytes(this.inspectionService.activeInspection);
    this.inspectionService.updateActiveInspectionInList(!(this.pwaService.isConnectedViaWifi() === false));
    this.saveStepProgress();
    this.router.navigate(['./vehicles'], { relativeTo: this.route.parent });
    this.dataLayerService.dataLayerInit(
      Events.FormSuccessSubmit,
      this.inspectionService.activeInspection.policyNumber,
      Names.Inspections,
      StepNames.SelfInspectionStep3
    );
    this.dataLayerService.pushToDataLayer();
  }
}
