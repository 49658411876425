import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WindscreenDamage } from '../../model/capture-windscreen-damage.model';

@Component({
  selector: 'tih-cwd-step3',
  templateUrl: './cwd-step3.component.html',
  styleUrls: ['../cwd-shared.scss']
})
export class CwdStep3Component {
  @Input() windscreenDamage: WindscreenDamage;

  @Output() nextStep = new EventEmitter<boolean>();
  @Output() previousStep = new EventEmitter<boolean>();

  hasAdditionalWindscreenDamage = false;
  damageConfirmed = false;
  damagePainted = false;

  proceed(): void {
    this.nextStep.emit(true);
  }

  emitWindscreenImage(value: string): void {
    this.windscreenDamage.area.dataUri = value;
  }

  previous(): void {
    this.previousStep.emit(true);
  }

  additionalWindscreenDamageChange(event): void {
    this.windscreenDamage.hasAdditionalWindscreenDamage = event;
  }
}
