import { Component, EventEmitter, Output } from '@angular/core';
import { UserService } from '../services/user.service';
import { ConfigurationService } from '../../modules/@tih/configuration/configuration.service';

@Component({
  selector: 'tih-mobi-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  isExpanded = false;

  @Output() setLoader = new EventEmitter<boolean>();

  constructor(public userService: UserService, private globalConfig: ConfigurationService) {}

  logout(): void {
    this.userService.logoutActiveUser();
  }

  goToDashboard(): void {
    this.isExpanded = false;
    this.setLoader.emit(true);
    window.location.href = `${this.globalConfig.baseBudgetUrl}/dashboard`;
  }

  collapse(): void {
    this.isExpanded = false;
  }

  toggle(): void {
    this.isExpanded = !this.isExpanded;
  }

  urlDecode(value: string): string {
    return decodeURIComponent(value);
  }
}
