import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tih-capture-odometer-reading',
  templateUrl: './capture-odometer-reading.component.html',
  styleUrls: ['./capture-odometer-reading.component.scss']
})
export class CaptureOdometerReadingComponent {
  @Output() backClick$ = new EventEmitter();
  @Output() confirmClick$ = new EventEmitter<number>();

  mileage = '';

  setMileage(mileage: number): void {
    if (mileage) {
      this.mileage = mileage.toString();
    }
  }

  backClick(): void {
    this.backClick$.emit();
  }

  confirmClick(mileage: string): void {
    const intMileage = Number.parseInt(mileage);
    this.confirmClick$.emit(intMileage);
  }
}
