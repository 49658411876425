import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SurveyQuestion, SurveyQuestionResponse } from '../../../../database-context/tables/vehicle-inspection.model';

@Component({
  selector: 'tih-survey-step',
  templateUrl: './survey-step.component.html',
  styleUrls: ['../../capture-survey-response.component.scss']
})
export class SurveySubmitStepComponent {

  private starRatingValue;
  private defaultStarSrc = '/assets/ratings/star-empty.svg';
  private selectedStarSrc = '/assets/ratings/star-blue.svg'

  private starRating: Array<{star: number, src: string}> = [
    { star: 1, src: this.defaultStarSrc },
    { star: 2, src: this.defaultStarSrc },
    { star: 3, src: this.defaultStarSrc },
    { star: 4, src: this.defaultStarSrc },
    { star: 5, src: this.defaultStarSrc }
  ];

  @Input() currentStep: number;
  @Input() survey: Array<SurveyQuestionResponse>;
  @Input() surveyQuestions: Array<SurveyQuestion>;

  @Output() confirmClick$ = new EventEmitter<Array<SurveyQuestionResponse>>();

  confirmClick() {
    this.confirmClick$.emit(this.survey);
  }

  changeStarColour(star, index) {
    this.resetStarRatingsToDefault();
    for (let i = 0; i < star.star; i++) {
      this.starRating[i].src = this.selectedStarSrc;
    }
    this.starRatingValue = star.star;
    this.survey[index].rating = this.starRatingValue;
  }

  resetStarRatingsToDefault() {
    for (let i = 0; i < this.starRating.length; i++) {
      this.starRating[i].src = this.defaultStarSrc;
    }
  }
}
