import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Events } from './enums/events.enum';
import { AddCover, DataLayer, Message } from './models/datalayer.model';
import { StepNames } from './enums/step-names.enum';
import { Names } from './enums/names.enum';

@Injectable({
  providedIn: 'root'
})
export class GtmDataLayerService {
  private renderer: Renderer2;
  private dataLayer: DataLayer;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  async addGtm(renderer: Renderer2, gtmId: string) {
    this.addGtmNoScript(renderer, gtmId);
    this.addGtmScript(renderer, gtmId);
  }

  private addGtmScript(renderer: Renderer2, gtmId: string): void {
    this.renderer = renderer;
    const gtmScript = this.renderer.createElement('script');
    gtmScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
        `;
    this.renderer.appendChild(this.document.head, gtmScript);
  }

  private addGtmNoScript(renderer: Renderer2, gtmId: string): void {
    this.renderer = renderer;
    const gtmNoScript = this.renderer.createElement('noscript');
    const iFrameNoScript = this.renderer.createElement('iframe');
    iFrameNoScript.src = `//www.googletagmanager.com/ns.html?id=${gtmId}`;
    iFrameNoScript.display = 'none';
    gtmNoScript.appendChild(iFrameNoScript);
    this.renderer.appendChild(this.document.head, gtmNoScript);
  }

  /* eslint-disable */
  pushToDataLayer(): void {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    if (this.dataLayer) {
      (<any>window).dataLayer.push(this.dataLayer);
    }
  }
  /* eslint-enable */

  addCoverToDataLayer(addCover: AddCover): void {
    this.dataLayer.form.addcover = addCover;
  }

  addMessageToDataLayer(message: Message): void {
    this.dataLayer.messages = message;
  }

  dataLayerInit(eventType: Events, policyNumber: string, name: Names, stepname: StepNames): void {
    this.dataLayer = new DataLayer();
    this.dataLayer.event = eventType;
    this.dataLayer.policynumber = policyNumber;
    this.dataLayer.form = {
      name: name,
      // ToDo add additional types when they are available
      type: 'amendments',
      stepname: stepname
    };
  }
}
