// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-component {
  border-radius: 100px;
  padding: 10px 15px;
  text-align: center;
  border: none;
  flex: 0.1;
  width: 100%;
  font-size: 16px;
  outline: none;
}
.button-component .button-component__icon {
  display: flex;
  justify-content: center;
}
.button-component .button-component__icon:before {
  align-items: center;
  display: flex;
  font-size: 18px;
}
.button-component .button-component__icon.icon--left {
  flex-direction: row;
}
.button-component .button-component__icon.icon--left .button-component__text {
  padding-left: 15px;
}
.button-component .button-component__icon.icon--right {
  flex-direction: row-reverse;
}
.button-component .button-component__icon.icon--right .button-component__text {
  padding-right: 15px;
}
.button-component:disabled, .button-component.disabled {
  background-color: var(--sub-3) !important;
  color: var(--sub-1) !important;
}
.button-component.m1 {
  background-color: var(--main-1);
  color: var(--sub-1);
}
.button-component.s5 {
  background-color: var(--sub-5);
  color: var(--sub-1);
}
.button-component.s5.inverse {
  background-color: transparent;
  border: 1px solid var(--sub-4);
}
.button-component.s5.inverse .button-component__icon {
  color: var(--sub-4);
}
.button-component.s5.inverse .button-component__icon .button-component__text {
  color: var(--sub-5);
}
.button-component.m6 {
  background-color: var(--main-6);
  color: var(--sub-1);
}
.button-component.m6:disabled, .button-component.m6.disabled, .button-component.m6.inverse {
  background-color: transparent;
  color: var(--sub-5);
  border: 1px solid;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
