import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RadioButton } from './radio-button.model';

@Component({
  selector: 'tih-radio-buttons',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RadioButtonComponent {
  @Input() allowMultipleSelections = false;
  @Input() items: RadioButton[];
  @Input() inputModel: boolean | string;

  @Output() selectedValue = new EventEmitter();
  @Output() selectedValues = new EventEmitter();

  valueHasChanged(value: boolean | string): void {
    this.selectedValue.emit(value);
  }

  multipleHasChanged(value: boolean | string): void {
    this.inputModel = value;

    setTimeout(() => {
      this.selectedValues.emit(this.items.filter((item: RadioButton) => item.checked));
    }, 0);
  }

  trackByIndex(_index: number, item: RadioButton) {
    return item.id;
  }
}
