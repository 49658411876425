// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(49, 49, 49, 0.7);
  align-items: center;
  justify-content: center;
  z-index: 5;
  padding: 15px 15px;
}
.overlay .dialog {
  background-color: white;
  width: 100%;
  max-width: 450px;
  min-width: 300px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.overlay .dialog .dialog-heading {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}
.overlay .dialog .dialog-heading.m1 {
  background-color: var(--main-1);
  color: var(--sub-2);
}
.overlay .dialog .dialog-heading .heading-icon {
  padding-top: 2px;
  font-size: 12px;
}
.overlay .dialog .dialog-content {
  overflow-y: scroll;
  max-height: -moz-fit-content;
  max-height: fit-content;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
