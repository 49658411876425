import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  EventEmitter,
  Injectable,
  Injector,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { CameraConfig } from '../camera.config';
import { CameraViewComponent } from '../view-components/camera.-view.component/camera-view.component';
import { GenericInjector } from '../../helpers/generic.injector';

@Injectable()
export class CameraService implements OnDestroy {
  private cameraComponentRef: ComponentRef<CameraViewComponent>;
  private overlayComponentRef: Subject<ComponentRef<any>> = new Subject<ComponentRef<any>>();

  onAfterClose = new EventEmitter<void>();
  private config: CameraConfig = new CameraConfig();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  private componentOnInit() {
    this.cameraComponentRef.instance.ngOnInit().then(() => {
      this.cameraComponentRef.instance.closeCamera.subscribe((value) => {
        if (value) {
          this.close();
        }
      });

      this.cameraComponentRef.instance.cameraResult.subscribe(() => {
        if (this.config.data?.autoClose) {
          this.close();
        }
      });

      if (this.config.overlayType) {
        this.cameraComponentRef.instance.overlayComponentRef.subscribe((value) => {
          if (value) {
            this.overlayComponentRef.next(value);
          }
        });
      }
    });
  }

  private appendCameraComponentToBody() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CameraViewComponent);

    const map = new WeakMap();
    map.set(CameraConfig, this.config);

    const componentRef = componentFactory.create(new GenericInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<CameraViewComponent>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.cameraComponentRef = componentRef;
    this.componentOnInit();
  }

  private removeCameraComponentFromBody() {
    this.appRef.detachView(this.cameraComponentRef.hostView);
    this.cameraComponentRef.destroy();
  }

  open(config?: CameraConfig): Promise<any>;
  async open(config?: CameraConfig): Promise<any> {
    this.config = config;
    this.appendCameraComponentToBody();
    if (config.overlayType) {
      this.cameraComponentRef.instance.overlayComponentType = config.overlayType;
    }

    return new Promise((resolve) => {
      resolve({ overlay: this.overlayComponentRef, camera: this.cameraComponentRef });
    });
  }

  private close() {
    this.removeCameraComponentFromBody();
    this.onAfterClose.emit();
  }

  ngOnDestroy() {
    this.config = new CameraConfig();
    this.cameraComponentRef?.instance?.ngOnDestroy();
    this.overlayComponentRef?.subscribe((component) => component.destroy()).unsubscribe();
  }
}
