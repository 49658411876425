// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-controls-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 15px;
  z-index: 1;
  position: relative;
  background-color: var(--sub-1);
}
.camera-controls-view .camera-controls-view__close {
  flex: 0.1;
}
.camera-controls-view .camera-controls-view__label {
  flex: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (orientation: landscape) {
  .camera-controls-view {
    flex-direction: column;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
