// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-overlay-view {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 0;
  padding: 15px;
  align-items: center;
}
.camera-overlay-view .camera-overlay-view__item-group {
  width: 50%;
  max-width: 90px;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__suggestion-group {
  visibility: hidden;
  border-radius: 30px;
  position: absolute;
  top: -60px;
  width: auto;
  display: flex;
}
@media (orientation: portrait) {
  .camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__suggestion-group {
    transform: rotate(90deg);
    top: auto;
    right: -60px;
    width: 70%;
  }
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__suggestion-group.active {
  visibility: visible;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__suggestion-group .suggestion__picture {
  background: var(--sub-1);
  width: 50px;
  height: 50px;
  border-radius: 45px;
  float: left;
  border: 3px solid var(--sub-1);
  display: flex;
  padding: 2px;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__suggestion-group .suggestion__picture img {
  width: 40px;
  height: 40px;
  justify-content: center;
  object-fit: cover;
  border-radius: 30px;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__suggestion-group .suggestion__picture:after {
  content: "";
  position: absolute;
  border-top: 7px solid var(--sub-1);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  bottom: 2px;
  left: 26px;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__suggestion-group .suggestion__text {
  text-align: center;
  background: var(--sub-1);
  border-radius: 45px;
  color: var(--sub-8);
  width: 100%;
  min-width: 95px;
  margin-left: -15px;
  z-index: -1;
  padding-left: 15px;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item {
  position: relative;
  font-size: 10px;
  margin-left: 5px;
}
@media (orientation: portrait) {
  .camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item {
    transform: rotate(90deg);
  }
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item.active .item__check {
  background-color: var(--main-1);
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item.active .item__check.icon {
  color: var(--main-1) !important;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item.active .item__check.icon:after {
  background-color: var(--sub-1);
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item.active .item__group {
  color: var(--main-1) !important;
  border: 2px solid;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item.active .item__group .item__empty {
  color: inherit;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item.active .item__group .item__empty .empty__icon:before {
  color: inherit;
}
@media only screen and (min-width: 560px) {
  .camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item.active .item__group .item__empty .empty__label {
    display: block;
  }
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__check {
  height: 20px;
  position: absolute;
  top: -7px;
  right: 0;
  width: 20px;
  background-color: var(--main-4);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: var(--sub-1);
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__check.icon {
  font-size: 20px;
  color: var(--main-4);
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__check.icon:before {
  position: relative;
  z-index: 2;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__check.icon:after {
  background-color: var(--sub-1);
  position: absolute;
  content: "";
  z-index: 1;
  border-radius: 100%;
  height: 15px;
  width: 15px;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group {
  position: relative;
  background-color: rgba(250, 250, 250, 0.8);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--sub-5);
  border-radius: 5px;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group.item__group--has-photo {
  color: var(--main-4);
  border: 2px solid;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group:first-child {
  margin: 0;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group .item__empty {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  font-size: 15px;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group .item__empty .empty__icon {
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group .item__empty .empty__label {
  font-size: 0.7em;
  text-align: center;
  display: none;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group .item__empty .empty__label.display-portrait {
  display: block !important;
}
.camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group .item__photo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}
@media (orientation: portrait) {
  .camera-overlay-view .camera-overlay-view__item-group .camera-overlay-view__item .item__group .item__photo {
    transform: rotate(-90deg);
  }
}
.camera-overlay-view .camera-overlay-view__item-group:nth-last-of-type(-n + 2) .camera-overlay-view__suggestion-group .suggestion__text {
  margin-left: -155px;
  padding-right: 15px;
  min-width: 120px;
}
.camera-overlay-view .camera-overlay-view__item-group:nth-of-type(-n + 2) .camera-overlay-view__suggestion-group .suggestion__text {
  margin-left: -15px !important;
  padding-right: 0px !important;
  min-width: 95px !important;
}
.camera-overlay-view .camera-overlay-view__done-button {
  position: relative;
  width: 50%;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: var(--main-1);
  color: var(--sub-1);
  border: none;
}
.camera-overlay-view .camera-overlay-view__done-button:disabled, .camera-overlay-view .camera-overlay-view__done-button.disabled {
  background-color: var(--sub-3) !important;
  color: var(--sub-1) !important;
}
.camera-overlay-view .camera-overlay-view__done-button:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.camera-overlay-view .camera-overlay-view__done-button .done-button__label {
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (orientation: portrait) {
  .camera-overlay-view .camera-overlay-view__done-button .done-button__label {
    transform: rotate(90deg);
  }
}
@media (orientation: portrait) {
  .camera-overlay-view {
    width: 120px;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
}
@media only screen and (orientation: portrait) and (max-width: 400px) {
  .camera-overlay-view {
    width: 100px;
  }
}
@media (orientation: portrait) {
  .camera-overlay-view .camera-overlay-view__item-group {
    width: 90%;
  }
  .camera-overlay-view .camera-overlay-view__item-group.make-smaller {
    width: 60%;
  }
  .camera-overlay-view .camera-overlay-view__item {
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
    max-width: 100px;
  }
  .camera-overlay-view .camera-overlay-view__item.active .item__group .item__empty .empty__icon {
    font-size: 3.5em;
  }
}
@media only screen and (orientation: portrait) and (min-width: 300px) {
  .camera-overlay-view .camera-overlay-view__item.active .item__group .item__empty .empty__label {
    font-size: 0.9em;
    display: block;
  }
}
@media (orientation: portrait) {
  .camera-overlay-view .camera-overlay-view__done-button {
    margin-left: 0;
    margin-top: 5px;
    width: 60px;
    height: 60px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
