import { Injectable } from '@angular/core';
import { VehicleInspection } from 'src/modules/@tih/budget/database-context/tables/vehicle-inspection.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StepValidationService {
  isStepValid(step: number, inspection: VehicleInspection) {
    if (!environment.production) {
      return true;
    }

    switch (step) {
      case 1:
        return this.isStepOneValid(inspection);
      case 2:
        return this.isStepTwoValid(inspection);
      case 3:
        return this.isStepThreeValid(inspection);
      default:
        return true;
    }
  }

  private isStepOneValid(inspection: VehicleInspection) {
    if (
      !inspection?.vehicle?.licenseDisk?.dataUri ||
      !(inspection?.driver?.licenseCard?.length > 0) ||
      !inspection?.vehicle?.odometer
    ) {
      return false;
    }

    return true;
  }

  private isStepTwoValid(inspection: VehicleInspection) {
    let inspectionSection = false,
      windcreenSection = false,
      damagesSection = false;

    if (inspection?.vehicle?.photos?.length > 0) {
      inspectionSection = true;
    }

    if (
      (inspection?.vehicle?.hasWindscreenDamages === true &&
        inspection?.vehicle?.windscreenDamage?.length &&
        inspection.vehicle.windscreenDamage[0].area?.dataUri &&
        inspection.vehicle.windscreenDamage[0].photo?.dataUri) ||
      inspection?.vehicle?.hasWindscreenDamages === false
    ) {
      windcreenSection = true;
    }

    if (
      (inspection?.vehicle?.hasDamages === true && inspection?.vehicle?.damages?.length > 0) ||
      inspection?.vehicle?.hasDamages === false
    ) {
      damagesSection = true;
    }

    if (inspectionSection && windcreenSection && damagesSection) {
      return true;
    } else {
      return false;
    }
  }

  private isStepThreeValid(inspection: VehicleInspection) {
    if (
      inspection?.vehicle?.accessories?.addons == null ||
      (inspection.vehicle.accessories.addons == true && !inspection.vehicle.accessories.description)
    ) {
      return false;
    }

    return true;
  }
}
