import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tih-additional-accessories',
  templateUrl: './additional-accessories.component.html',
  styleUrls: ['./additional-accessories.component.scss']
})
export class AdditionalAccessoriesComponent {
  @Output() backClick$ = new EventEmitter<void>();
  @Output() confirmClick$ = new EventEmitter();

  additionalAccessories: any;

  additionalAccessories$ = new Subject<any>();

  initialQuestion = false;
  descriptionQuestion = false;

  radioItems = [
    {
      id: 1,
      description: 'Yes',
      value: true,
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2'
    },
    {
      id: 2,
      description: 'No',
      value: false,
      checked: false,
      unCheckedIcon: 'icon-outline',
      checkedIcon: 'icon-check-2'
    }
  ];

  constructor() {
    this.additionalAccessories$.subscribe((accessories) => {
      if (accessories) {
        this.additionalAccessories = accessories;
        if (this.additionalAccessories?.addons) {
          this.descriptionQuestion = true;
        } else {
          this.initialQuestion = true;
        }
      }
    });
  }

  additionalAccessoriesChange(event) {
    this.additionalAccessories.addons = event;
  }

  backClick() {
    if (this.initialQuestion) {
      this.additionalAccessories.addons = null;
      this.backClick$.emit();
    } else if (this.descriptionQuestion) {
      this.initialQuestion = true;
      this.descriptionQuestion = false;
    }
  }

  confirmClick(addInfo: boolean) {
    if (this.initialQuestion) {
      if (addInfo) {
        this.initialQuestion = false;
        this.descriptionQuestion = true;
        this.additionalAccessories.addons = true;
      } else {
        this.additionalAccessories.addons = false;
        this.additionalAccessories.description = null;
        this.confirmClick$.emit(this.additionalAccessories);
      }
    } else if (this.descriptionQuestion) {
      this.confirmClick$.emit(this.additionalAccessories);
    }
  }
}
