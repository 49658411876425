// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description-component .description-component__heading {
  font-weight: 600;
  color: var(--sub-6);
}
.description-component .description-component__copy {
  color: var(--sub-5);
}

.step-component {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.step-component .step-component__step {
  padding: 10px;
  text-align: center;
  width: 100%;
  background-color: white;
  border: 1px solid var(--sub-4);
}
.step-component .step-component__step.step--current {
  background-color: var(--main-1);
  color: var(--sub-1);
}
.step-component .step-component__step:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.step-component .step-component__step:last-child {
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.toggle-view-container {
  display: flex;
  flex-direction: column;
}

.damage-comments-view {
  width: 100%;
}
.damage-comments-view .damage-comments-view__input-label {
  color: var(--sub-8);
  font-weight: 600;
}
.damage-comments-view .damage-comments-view__input-container {
  border: 1px solid var(--sub-4);
  outline: none;
  width: 100%;
}
.damage-comments-view .damage-comments-view__input-container .input-container__input {
  border: none;
  width: 100%;
  outline: none;
  color: var(--sub-6);
  font-weight: 600;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
