import { Component, EventEmitter, Output } from '@angular/core';
import { PwaConfig } from '../../pwa.config';

@Component({
  selector: 'tih-pwa-prompt',
  templateUrl: './pwa-prompt.component.html',
  styleUrls: ['../pwa-already-installed-component/pwa-already-installed.component.scss', './pwa-prompt.component.scss']
})
export class PwaPromptComponent {
  @Output() promptClick = new EventEmitter();

  constructor(public config: PwaConfig) {}

  sendToHomeScreen(): void {
    this.promptClick.next();
  }
}
