import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'tih-camera-controls',
  templateUrl: './camera-controls.component.html',
  styleUrls: ['./camera-controls.component.scss']
})
export class CameraControlsComponent {
  @Input() label = 'Stream Active';

  @Output() closeCamera = new EventEmitter<boolean>();

  close(): void {
    this.closeCamera.emit(true);
  }
}
